import React from "react";
import { Chart } from "react-google-charts";

// Example Data
// const data = [
//   ["Date", "Posted", "Accepted"],
//   ["01/05/2024", 50, 65],
//   ["02/05/2024", 40, 55],
//   ["03/05/2024", 70, 80],
//   ["04/05/2024", 55, 70],
//   ["05/05/2024", 60, 85],
//   ["06/05/2024", 30, 45],
//   ["07/05/2024", 90, 120],
// ];

const options = {
  title: "Bid weekly details",
  curveType: "function",
  legend: { position: "bottom" },
};

export function ChartView({ data }) {
  return (
    <Chart
      chartType="LineChart"
      width="100%"
      height="400px"
      data={data}
      options={options}
    />
  );
}
