import React, { useState } from "react";
import validate from "../components/ValidateLogin";
import { useFormik } from "formik";
import { authLogin } from "../api_url/authOrganization";
import { getOrganizationByEmail } from "../api_url/organizationTable";
import { useAuth } from "../context/AuthContext";

function LoginForm() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);

  const { updateAuthToken } = useAuth();

  const signIn = async (email, password) => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      await authLogin({ email: email, password: password })
        .then(async resp => {
          await getOrganizationByEmail(resp?.authToken, email).then(async (res) => {
            await updateAuthToken(resp?.authToken, res?.id?.toString(), res?.uniqueId?.toString());
            setError(undefined);
            setLoading(false);
          }).catch(e => {
            setError(e?.message);
            setLoading(false);
          });
        }).catch(e => {
          setError(e?.message);
          setLoading(false);
        });
    } catch (error) {
      console.log("error signing in", error.message);
      setError(error.message);
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validate,
    onSubmit: (values) => {
      signIn(values.email, values.password);
    },
  });

  return (
    <div className="select-none mt-10 flex justify-center items-center">
      <div
        className="flex-1 bg-white 
             md:rounded-tl-xl md:rounded-bl-xl  md:rounded-none
           justify-center items-center"
      >
        <form onSubmit={formik.handleSubmit}>
          <div className="bg-grey-lighter p-12 px-16 flex flex-col">
            <div
              className="container max-w-sm
                   mx-auto flex-1 flex flex-col
                    items-center justify-center"
            >
              <div className="w-full">
                <h1 className="text-2xl font-bold txtGreen text-center">
                  Welcome back , Please Sign In
                </h1>

                <div className="w-full flex justify-center">
                  <div
                    className="bgGreen rounded-full 
                      h-1 w-16 mb-8"
                  />
                </div>
                <div className="space-y-3">
                  <div>
                    <input
                      type="text"
                      className="block border border-grey-light w-full p-3 h-10 rounded"
                      name="email"
                      placeholder="Email"
                      {...formik.getFieldProps("email")}
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <div>{formik.errors.email}</div>
                    ) : null}
                  </div>
                  <div>
                    <input
                      type="password"
                      className="block border border-grey-light w-full p-3 h-10 rounded"
                      name="password"
                      placeholder="Password"
                      {...formik.getFieldProps("password")}
                    />
                    {formik.touched.password && formik.errors.password ? (
                      <div>{formik.errors.password}</div>
                    ) : null}
                  </div>

                  <p className="text-center text-red-500">
                    {error !== undefined && error}
                  </p>

                  <div>
                    <button
                      type="submit"
                      className="w-full text-center py-2 rounded
                           active:bg-blue-300 bg-blue-500 font-bold
                          text-white hover:bg-green-dark focus:outline-none my-1"
                    >
                      {loading ? "Loading..." : "Sign In"}
                    </button>
                  </div>

                  <div className="text-center mt-4">
                    <p className="text-sm text-gray-600">
                      By logging in, you agree to our{" "}
                      <a 
                        href="https://nursdinc.com/terms" 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="text-blue-500 underline"
                      >
                        Terms
                      </a>
                      {" "}and acknowledge that you have read our{" "}
                      <a 
                        href="https://nursdinc.com/privacy-policy" 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="text-blue-500 underline"
                      >
                        Privacy Policy
                      </a>
                      .
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default LoginForm;