import React, { useEffect, useState, useRef } from "react";
import healthcareImage from "../assets/nurse-img.jpg";
import retainIcon from "../assets/search-icon.jpg";
import recruitIcon from "../assets/target-icon.jpg";
import rebalanceIcon from "../assets/menu-icon.jpg";
import reduceIcon from "../assets/reduce-icon (1).png";
import backgroundImage from "../assets/background.jpg";
import slideImage1 from "../assets/week.jpg";
import slideImage2 from "../assets/task.jpg";
import slideImage3 from "../assets/sechedule.png";
import slideImage4 from "../assets/new.jpg";
import mapImage from "../assets/navigation.gif";

function Dashboard() {
  const [isVisible, setIsVisible] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const sectionRef = useRef(null);

  const slides = [
    {
      image: slideImage1,
      title: "Maximize Productivity",
      subheading: "Real-time Schedule, Manage and Communicate",
      description:
        "Introducing our smart manager for healthcare workers – your ultimate shift management solution. Seamlessly integrating with your workflow, this intelligent tool optimizes scheduling, streamlines communication, and ensures efficient staff allocation. Say goodbye to scheduling headaches and hello to effortless shift management with our smart calendar",
    },
    {
      image: slideImage2,
      title: "Minimize Compliance Risk",
      subheading: "Compliance made easy. Our smart system ensures stress-free healthcare worker profile management.",
      description:
        "Experience peace of mind with our smart employee profile management system tailored for healthcare workers. Our innovative solution minimizes compliance risks by centralizing and automating credential management, training tracking, and certification renewals. Stay ahead of regulatory requirements effortlessly, ensuring your workforce is always compliant and ready to deliver exceptional care.",
    },
    {
      image: slideImage3,
      title: "Maximize Fulfillment",
      subheading: "Maximize fulfillment, empower your team.",
      description:
        "Unlock peak fulfillment for healthcare workers with our smart calendar management system. Seamlessly optimizing shifts, communication, and efficiency, it ensures every moment counts, empowering your team to deliver their best.",
    },
    {
      image: slideImage4,
      title: "Empower Your Business with Real-Time Insights",
      subheading: "Discover actionable insights in real-time with our cutting-edge reporting software.",
      description:
        "Welcome to our real-time reporting software, where data meets insight, instantly. Our cutting-edge reporting tool empowers businesses to stay ahead in today's fast-paced world by providing up-to-the-second analytics and insights. Say goodbye to outdated reports and hello to actionable intelligence in real-time. With our software, you can monitor key metrics, track performance, and make informed decisions on the fly. Whether you're in the boardroom or on the go, access critical data anytime, anywhere, and on any device.",
    },
  ];

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      },
      { threshold: 0.5 }
    );

    if (sectionRef.current) observer.observe(sectionRef.current);

    return () => {
      if (sectionRef.current) observer.unobserve(sectionRef.current);
    };
  }, []);

  useEffect(() => {
    const slideInterval = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % slides.length);
    }, 5000);

    return () => clearInterval(slideInterval);
  }, [slides.length]);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const goToPreviousSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
  };

  const goToNextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % slides.length);
  };

  return (
    <div style={{ maxWidth: "100%", overflowX: "hidden", boxSizing: "border-box" }}>
      {/* Hero Section */}
      <div
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          padding: isMobile ? "50px 10px" : "100px 20px",
          textAlign: "center",
          color: "#fff",
          height: isMobile ? "400px" : "600px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h3
          style={{
            color: "#fff",
            fontSize: isMobile ? "1.5rem" : "2rem",
            fontWeight: 600,
            marginBottom: "1rem",
          }}
        >
          NURSD Health Platform
        </h3>
        <div
          style={{
            fontSize: isMobile ? "1rem" : "1.2rem",
            fontWeight: 500,
            lineHeight: 1.5,
            maxWidth: isMobile ? "90%" : "600px",
          }}
        >
          <p style={{ margin: 0 }}>
            Tired of the inefficiencies and frustrations of traditional
          </p>
          <p style={{ margin: 0 }}>healthcare staffing processes?</p>
        </div>
      </div>

      {/* Introduction Section */}
      <div
        style={{
          padding: isMobile ? "1rem" : "2rem 1rem",
          textAlign: "center",
          backgroundColor: "#f9f9f9",
          color: "#333",
        }}
      >
        <h2
          style={{
            color: "#00b4d8",
            fontSize: isMobile ? "1.5rem" : "1.8rem",
            fontWeight: 600,
            marginBottom: "1rem",
          }}
        >
          A NextGen HR and intelligent staffing software for healthcare facilities.
        </h2>
        <p
          style={{
            fontSize: isMobile ? "0.9rem" : "1rem",
            lineHeight: 1.6,
            maxWidth: "90%",
            margin: "0 auto",
          }}
        >
          Empowering healthcare organizations with a Scalable Platform for creating
          Balanced, Flexible workforce Models. NURSD is transforming the healthcare
          workforce environment with unparalleled matching technology powered by
          artificial intelligence, removing the tedious and manual process of
          scheduling shifts at healthcare organizations such as Health Systems &
          Hospitals, Skilled Nursing Facilities.
        </p>
      </div>

      {/* Case Study Section */}
      <div
        ref={sectionRef}
        style={{
          position: "relative",
          width: "100%",
          minHeight: isMobile ? "500px" : "850px",
          backgroundImage: `url(${healthcareImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top center",
          backgroundRepeat: "no-repeat",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          color: "#fff",
          textAlign: "left",
          padding: isMobile ? "1rem" : "2rem",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            zIndex: 1,
          }}
        />
        <div
          style={{
            position: "relative",
            zIndex: 2,
            transform: isVisible ? "translateY(0)" : "translateY(50px)",
            opacity: isVisible ? 1 : 0,
            transition: "transform 1s ease, opacity 1s ease",
            maxWidth: isMobile ? "100%" : "600px",
            margin: isMobile ? "10px" : "20px 40px",
            textAlign: "left",
          }}
        >
          <h2
            style={{
              color: "#00b4d8",
              margin: isMobile ? "0 10px" : "0px 40px",
              fontSize: isMobile ? "1.5rem" : "2rem",
              fontWeight: 600,
            }}
          >
            Case Study
          </h2>
          <p
            style={{
              fontSize: isMobile ? "1.2rem" : "28px",
              padding: isMobile ? "10px" : "10px 40px",
            }}
          >
            Learn how a multistate skilled nursing group revolutionized their workforce and saved on average 23% on contingent staffing needs.
          </p>
          <button
            style={{
              padding: isMobile ? "8px 15px" : "10px 20px",
              fontSize: isMobile ? "0.9rem" : "1rem",
              fontWeight: 600,
              backgroundColor: "#28a745",
              color: "#fff",
              border: "none",
              borderRadius: 5,
              cursor: "pointer",
              textTransform: "uppercase",
              margin: isMobile ? "0 10px" : "0px 40px",
            }}
            onClick={() => (window.location.href = "https://nursdinc.com/contact-us")}
          >
            Get in Touch
          </button>
        </div>
      </div>

      {/* Frictionless Workforce Solutions Section */}
      <div
        style={{
          padding: isMobile ? "1rem" : "2rem 1rem",
          backgroundColor: "#fff",
          textAlign: "center",
        }}
      >
        <h2
          style={{
            color: "#00b4d8",
            fontSize: isMobile ? "1.5rem" : "1.8rem",
            fontWeight: 600,
            marginBottom: "1rem",
          }}
        >
          Frictionless Workforce Solutions
        </h2>
        <p
          style={{
            fontSize: isMobile ? "0.9rem" : "1rem",
            lineHeight: 1.6,
            marginBottom: "2rem",
            maxWidth: "90%",
            margin: "0 auto",
          }}
        >
          NURSD empowers healthcare systems and hospitals to simultaneously manage their internal labor pools and tap into external float pool from their staffing partners – leading to less reliance on agencies, lower labor costs, and increased retention rates. Custom-branded for your organization, Powered by NURSD encompasses internal resource management, workforce automation, cost management, and external resource management to help you:
        </p>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            gap: isMobile ? "0.5rem" : "1rem",
            maxWidth: "100%",
          }}
        >
          {[
            {
              icon: retainIcon,
              title: "Retain",
              description:
                "Wages alone aren’t enough to retain employees. Enhance the employee experience by seamlessly engaging and communicating with internal staff about open shifts and creative benefits that incentivize the workforce.",
            },
            {
              icon: recruitIcon,
              title: "Recruit",
              description:
                "Recruit from within. Fill shifts fast with a holistic view of your own full-time and float workforce first. Tap into your contingent staffing partners to fill gaps along the way.",
            },
            {
              icon: rebalanceIcon,
              title: "Rebalance",
              description:
                "Make shift fulfillment effective and efficient by using AI-powered resources to easily identify the biggest staffing needs and rebalance your FTEs and labor pools, using the most cost-effective resources first.",
            },
            {
              icon: reduceIcon,
              title: "Reduce",
              description:
                "Create a sustainable workforce model. Innovative tools help you reduce overall expenses while continuing to engage, support, and attract your workforce.",
            },
          ].map((item, index) => (
            <div
              key={index}
              style={{
                textAlign: "center",
                width: isMobile ? "100%" : "23%",
                maxWidth: isMobile ? "100%" : "300px",
                padding: "1rem",
                borderRadius: "10px",
                transition: "transform 0.3s ease, box-shadow 0.3s ease",
                cursor: "pointer",
              }}
              onMouseEnter={(e) => {
                if (!isMobile) {
                  e.currentTarget.style.transform = "scale(1.05)";
                  e.currentTarget.style.boxShadow = "0 4px 20px rgba(0, 0, 0, 0.1)";
                }
              }}
              onMouseLeave={(e) => {
                if (!isMobile) {
                  e.currentTarget.style.transform = "scale(1)";
                  e.currentTarget.style.boxShadow = "none";
                }
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "1rem",
                }}
              >
                <img
                  src={item.icon}
                  alt={item.title}
                  style={{ width: isMobile ? 40 : 50, height: isMobile ? 40 : 50 }}
                />
              </div>
              <h3
                style={{
                  color: "#00b4d8",
                  fontSize: isMobile ? "1rem" : "1.2rem",
                  fontWeight: 600,
                  marginBottom: "0.5rem",
                }}
              >
                {item.title}
              </h3>
              <p style={{ fontSize: isMobile ? "0.8rem" : "0.9rem" }}>{item.description}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Slideshow Section */}
      <div
        style={{
          position: "relative",
          width: "100%",
          height: isMobile ? "500px" : "600px",
          overflow: "hidden",
        }}
      >
        {slides.map((slide, index) => (
          <div
            key={index}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundImage: `url(${slide.image})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              opacity: currentSlide === index ? 1 : 0,
              transition: "opacity 1s ease-in-out",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.8)",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                padding: isMobile ? "10px" : "20px",
              }}
            >
              <div
                style={{
                  maxWidth: isMobile ? "100%" : "600px",
                  color: "#fff",
                  padding: isMobile ? "10px" : "20px",
                  textAlign: "left",
                }}
              >
                <h2
                  style={{
                    fontSize: isMobile ? "1.5rem" : "2.5rem",
                    margin: "0 0 10px 0",
                    fontWeight: 700,
                    color: "#00b4d8",
                  }}
                >
                  {slide.title}
                </h2>
                <h5
                  style={{
                    fontSize: isMobile ? "1rem" : "1.5rem",
                    margin: "0 0 20px 0",
                    fontWeight: 500,
                    color: "#FFFF00",
                  }}
                >
                  {slide.subheading}
                </h5>
                <p
                  style={{
                    fontSize: isMobile ? "0.9rem" : "1rem",
                    lineHeight: 1.6,
                    margin: 0,
                  }}
                >
                  {slide.description}
                </p>
              </div>
            </div>
          </div>
        ))}

        {/* Navigation Arrows */}
        <button
          onClick={goToPreviousSlide}
          style={{
            position: "absolute",
            top: "50%",
            left: "20px",
            transform: "translateY(-50%)",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            border: "none",
            color: "#fff",
            fontSize: isMobile ? "1.5rem" : "2rem",
            padding: "10px",
            cursor: "pointer",
            borderRadius: "50%",
            width: isMobile ? "30px" : "40px",
            height: isMobile ? "30px" : "40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 10,
          }}
        >
          ‹
        </button>
        <button
          onClick={goToNextSlide}
          style={{
            position: "absolute",
            top: "50%",
            right: "20px",
            transform: "translateY(-50%)",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            border: "none",
            color: "#fff",
            fontSize: isMobile ? "1.5rem" : "2rem",
            padding: "10px",
            cursor: "pointer",
            borderRadius: "50%",
            width: isMobile ? "30px" : "40px",
            height: isMobile ? "30px" : "40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 10,
          }}
        >
          ›
        </button>
      </div>

      {/* Updated GIF Section */}
      <div
        style={{
          position: "relative",
          width: "100%",
          height: isMobile ? "50vh" : "100vh",
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "left",
          overflow: "hidden",
        }}
      >
        {/* GIF as an <img> element */}
        <img
          src={mapImage}
          alt="Navigation Map"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
            objectPosition: "center",
            zIndex: 0,
            display: "block",
          }}
          onError={(e) => {
            console.error("Error loading GIF:", e);
            e.target.style.display = "none";
          }}
        />

        {/* Overlay for Contrast */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 1,
          }}
        />

        {/* Text Content */}
        <div
          style={{
            position: "relative",
            zIndex: 2,
            maxWidth: isMobile ? "90%" : "1200px",
            padding: isMobile ? "20px" : "40px",
            color: "white",
            textAlign: "left",
          }}
        >
          
         
        </div>
      </div>
    </div>
  );
}

export default Dashboard;