import React, { useState, useEffect } from "react";

function SideTab({ initialTab = "about" }) {
  const [tab, setTab] = useState(initialTab);

  useEffect(() => {
    setTab(initialTab);
  }, [initialTab]);

  return (
    <div
      style={{
        maxWidth: "100%",
        padding: "20px",
        backgroundColor: "#f5f7fa",
        minHeight: "100vh",
        fontFamily: "'Roboto', sans-serif",
      }}
    >
      <section
        style={{
          maxWidth: "1200px",
          margin: "0 auto",
          display: "flex",
          flexDirection: "column",
          gap: "30px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: window.innerWidth <= 768 ? "column" : "row",
            gap: "30 Sternpx",
          }}
        >
          {/* Tab Navigation */}
          <div
            style={{
              flex: window.innerWidth <= 768 ? "none" : "0 0 250px",
              backgroundColor: "#ffffff",
              borderRadius: "12px",
              boxShadow: "0 6px 12px rgba(0, 0, 0, 0.1)",
              padding: "25px",
              position: window.innerWidth > 768 ? "sticky" : "static",
              top: "20px",
              height: window.innerWidth > 768 ? "fit-content" : "auto",
            }}
          >
            <ul
              style={{
                listStyle: "none",
                padding: 0,
                margin: 0,
                display: "flex",
                flexDirection: window.innerWidth <= 768 ? "row" : "column",
                gap: window.innerWidth <= 768 ? "15px" : "20px",
                flexWrap: window.innerWidth <= 768 ? "wrap" : "nowrap",
              }}
            >
              {[
                { id: "about", label: "About" },
                { id: "how", label: "How it Works" },
                { id: "privacy", label: "Privacy Policy" },
                { id: "terms", label: "Terms of Use" },
              ].map((item) => (
                <li
                  key={item.id}
                  style={{ width: window.innerWidth <= 768 ? "auto" : "100%" }}
                >
                  <a
                    onClick={() => setTab(item.id)}
                    style={{
                      display: "block",
                      padding: "14px 20px",
                      textDecoration: "none",
                      color: tab === item.id ? "#007bff" : "#333",
                      fontWeight: tab === item.id ? "600" : "400",
                      fontSize: "1.1rem",
                      backgroundColor:
                        tab === item.id ? "#e6f0ff" : "transparent",
                      borderRadius: "10px",
                      cursor: "pointer",
                      transition: "all 0.3s ease",
                      boxShadow:
                        tab === item.id
                          ? "inset 0 0 5px rgba(0, 123, 255, 0.3)"
                          : "none",
                    }}
                    onMouseEnter={(e) => {
                      if (tab !== item.id)
                        e.target.style.backgroundColor = "#f5f5f5";
                    }}
                    onMouseLeave={(e) => {
                      if (tab !== item.id)
                        e.target.style.backgroundColor = "transparent";
                    }}
                  >
                    <span>{item.label}</span>
                  </a>
                </li>
              ))}
            </ul>
          </div>

          {/* Tab Content */}
          <div
            style={{
              flex: "1",
              backgroundColor: "#ffffff",
              borderRadius: "12px",
              boxShadow: "0 6px 12px rgba(0, 0, 0, 0.1)",
              padding: "35px",
              overflowY: "auto",
              transition: "opacity 0.3s ease",
            }}
          >
            {/* About Section */}
            <section
              style={{
                display: tab === "about" ? "block" : "none",
              }}
            >
              <h1
                style={{
                  color: "#007bff",
                  fontSize: "2rem",
                  marginBottom: "20px",
                }}
              >
                Welcome to NURSD Health
              </h1>
              <p
                style={{
                  fontSize: "1rem",
                  lineHeight: "1.6",
                  marginBottom: "15px",
                }}
              >
                NURSD is founded with a bold vision to revolutionize the
                healthcare workforce industry with a potent blend of human
                behavior science, AI, and cutting-edge technology. Founded by
                caregivers and technology veterans, our mission is deeply rooted
                in addressing the critical needs of both caregivers and
                healthcare facilities. At NURSD Health, we understand the
                challenges that plague the healthcare staffing industry: from
                inefficient staffing processes to the burnout experienced by
                caregivers. That's why we've developed a groundbreaking platform
                that not only streamlines the staffing process but also
                prioritizes the well-being and satisfaction of caregivers. Why
                did we create NURSD Health? What sets us apart is our unwavering
                commitment to enhancing the caregiver experience. We believe
                that by prioritizing the well-being of caregivers, we ultimately
                improve patient outcomes and elevate the standard of care across
                the industry. Our platform provides caregivers with the support
                they need, from flexible scheduling options to access to
                resources for professional development and self-care. What do we
                do? By harnessing the power of human behavior science, we have
                created an intuitive platform that anticipates the needs of both
                caregivers and healthcare facilities, ensuring optimal matches
                and seamless staffing transitions. Our AI-driven algorithms
                analyze vast amounts of data to predict staffing demands
                accurately, allowing us to provide timely solutions and prevent
                understaffing crises.
              </p>
              <p
                style={{
                  fontSize: "1rem",
                  lineHeight: "1.6",
                  marginBottom: "15px",
                }}
              >
                Our unparalleled matching technology understands the needs of
                healthcare facilities and nursing professionals at a hyper-local
                level to improve care by automatically optimizing and augmenting
                staff.
              </p>
              <p
                style={{
                  fontSize: "1rem",
                  lineHeight: "1.6",
                  marginBottom: "15px",
                }}
              >
                We give nurses and CNAs the freedom and flexibility to pick up
                shifts right from our mobile app.
              </p>
              <p
                style={{
                  fontSize: "1rem",
                  lineHeight: "1.6",
                  marginBottom: "15px",
                }}
              >
                We aim to solve the last-minute staffing problem and improve
                Care quality and patient safety. We built the NURSD Health
                Platform to return caregiving to its roots.
              </p>
            </section>

            {/* How it Works Section */}
            <section
              style={{
                display: tab === "how" ? "block" : "none",
              }}
            >
              <h1 style={{ fontSize: "2rem", marginBottom: "20px" }}>
                How it works
              </h1>
              <p
                style={{
                  fontSize: "1rem",
                  lineHeight: "1.6",
                  marginBottom: "15px",
                }}
              >
                Here you can find information about how NURSD Health works.
              </p>
              <h3
                style={{
                  color: "#007bff",
                  fontSize: "1.5rem",
                  margin: "20px 0 10px",
                }}
              >
                For Healthcare Facilities
              </h3>
              <p
                style={{
                  fontSize: "1rem",
                  lineHeight: "1.6",
                  marginBottom: "10px",
                }}
              >
                Post your requirement. Tell us your need. It&apos;s FREE to
                post.
              </p>
              <p
                style={{
                  fontSize: "1rem",
                  lineHeight: "1.6",
                  marginBottom: "10px",
                }}
              >
                Healthcare talent 24/7. Get offers from trusted nurses and view
                profiles.
              </p>
              <p
                style={{
                  fontSize: "1rem",
                  lineHeight: "1.6",
                  marginBottom: "10px",
                }}
              >
                Submit and leave it to us. Choose the right person for your
                requirement and get the job done.
              </p>
              <h3
                style={{
                  color: "#007bff",
                  fontSize: "1.5rem",
                  margin: "20px 0 10px",
                }}
              >
                How to Join as a Nurse
              </h3>
              <p
                style={{
                  fontSize: "1rem",
                  lineHeight: "1.6",
                  marginBottom: "10px",
                }}
              >
                Register, Login or signup as a freelancer.
              </p>
              <p
                style={{
                  fontSize: "1rem",
                  lineHeight: "1.6",
                  marginBottom: "10px",
                }}
              >
                Find a Job. Search for available shifts close to your home.
              </p>
              <p
                style={{
                  fontSize: "1rem",
                  lineHeight: "1.6",
                  marginBottom: "10px",
                }}
              >
                Apply and leave it to us. Do what you love most "taking care of
                patients" and start earning.
              </p>
            </section>

            {/* Privacy Policy Section */}
            <section
              style={{
                display: tab === "privacy" ? "block" : "none",
              }}
            >
              <h2
                style={{
                  color: "#007bff",
                  fontSize: "1.75rem",
                  fontWeight: "600",
                  marginBottom: "15px",
                }}
              >
                NURSD Privacy Policy
              </h2>
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: "1rem",
                  marginBottom: "15px",
                }}
              >
                Effective Date: December 4, 2023
              </p>
              <p
                style={{
                  fontSize: "1rem",
                  lineHeight: "1.6",
                  marginBottom: "15px",
                }}
              >
                Thank you for visiting www.nursdinc.com (the “Site”) or
                www.nursdhealth.com (the “Site”) or www.nursd.app (the “Site”),
                owned and operated by NURSD LLC. (“NURSD LLC.,” “we,” or “our”).
                We are committed to treating the personal and corporate
                information of our users (“user,” “users,” “you,” or “your”)
                with respect and sensitivity. This privacy policy (the “Privacy
                Policy”) is part of our Terms and Conditions of Use of NURSD
                LLC. Site and Services (the “Terms”). Please click here to see
                the Terms.Application of this Privacy PolicyThis Privacy Policy
                describes how we treat information that we collect both through
                our Site as well as through any associated mobile application.
                Changes to our Privacy PolicyAs our services evolve and we
                perceive the need or desirability of using information collected
                in other ways, we may from time to time amend this Privacy
                Policy. We encourage you to check our Site frequently to see the
                current Privacy Policy and Terms in effect and any changes that
                may have been made to them. If we make material changes to this
                Privacy Policy, we will post the revised Privacy Policy and the
                revised effective date on this Site. Information You Provide
                UsIn general, you can visit the Site without telling us who you
                are or revealing any information about yourself. However, if you
                wish to contact us through the Contact Us link in our site
                footer or by emailing us at support@nursdhealth.com, we may
                collect your name, company name, email address, telephone number
                and street address along with other information you may choose
                to submit, as part of the inquiry process. When you register for
                a NURSD LLC. account as a Client (as defined in the Terms), we
                collect information from you that may include your name, company
                name, email address, telephone number, street address, taxpayer
                and immigration information, payroll, insurance and banking
                information as well as other information that you submit as part
                of your profile or job posting. We may also collect information
                about you from other publicly-available sources, which we may
                combine with the other information we have. When you register
                for a NURSD LLC. account as a Nurse (as defined in the Terms),
                we collect information from you that may include your name,
                email address, telephone number, street address, taxpayer and
                immigration information, education and work history and
                credentialing information as well as other information that you
                submit as part of your profile or job posting. We may also
                collect information about you from other publicly available
                sources, which we may combine with the other information we
                have. You may be asked to choose a user name, such as your email
                address, and a password that will be used to access your
                account. By providing your information to us, you explicitly
                agree to our collection and use of such information as described
                in this Privacy Policy. The information you provide us must be
                accurate and up to date. We may verify or validate the
                information that you provide us through third parties.
                Information Automatically CollectedWe (or our service providers
                acting on our behalf) may collect information about how you use
                our Site or our services. This information includes information
                that may identify you as well as statistical information
                (“Analytics”) that does not identify you. Some Analytics may be
                correlated with your account information. Usage information may
                include: 1. Device-specific information (such as your hardware
                model, operating system version, unique Device identifiers and
                mobile network information, including phone number). We may
                associate your Device identifiers or phone number with your
                NURSD LLC. account. For purposes of this Privacy Policy, a
                “Device” is any computer used to access the Site, including
                without limitation a desktop, laptop, mobile phone, tablet, or
                other consumer electronic Device. 2. Log information recorded
                when you use our Site including your Device, its software, and
                Device identifiers and mobile network information, including
                phone number). We may associate your Device identifiers or phone
                number with your NURSD LLC. account. For purposes of this
                Privacy Policy, a “Device” is any computer used to access the
                Site, including without limitation a desktop, laptop, mobile
                phone, tablet, or other consumer electronic Device. Your
                activity using the Site. This may include: • The Device’s
                Internet Protocol (“IP”) address;• Identification numbers
                associated with your Devices;• Device event information such as
                crashes, system activity and hardware settings;• Locale
                preferences;• Application activity such as app upload, app
                download, app installation, app uninstallation, app start, app
                play duration, app stop, app crashes, app network activity and
                app resource consumption;• Date and time stamps associated with
                transactions;• System configuration information;• Metadata
                concerning your mobile or smartphone apps; and• Other
                interactions with our Site. 3. Location information. When you
                use a location-enabled Device, we may collect, process and
                retain information about your actual location if you have so
                agreed. We may also use various technologies to determine
                location, such as sensor data from your Device that may, for
                example, provide information on nearby Wi-Fi access points and
                cell towers. The location data is collected anonymously in a
                form that does not personally identify you and is used by us to
                provide and improve location-based products and services. By
                using a location-enabled app, you consent to us processing
                information about your actual location.
              </p>

              <h3
                style={{
                  color: "#007bff",
                  fontSize: "1.25rem",
                  margin: "20px 0 10px",
                }}
              >
                Protecting Children’s Privacy
              </h3>
              <p
                style={{
                  fontSize: "1rem",
                  lineHeight: "1.6",
                  marginBottom: "15px",
                }}
              >
                This Site is not designed nor intended to be attractive to use
                by children under the age of 18 (“Minors”). We do not knowingly
                collect information from Minors. If you are under the age of 18,
                please do not submit any information to us.
              </p>

              <h3
                style={{
                  color: "#007bff",
                  fontSize: "1.25rem",
                  margin: "20px 0 10px",
                }}
              >
                Cookies
              </h3>
              <p
                style={{
                  fontSize: "1rem",
                  lineHeight: "1.6",
                  marginBottom: "15px",
                }}
              >
                We use cookies to personalize our Site for you and to collect
                aggregate information about Site usage by all of our users. A
                cookie is a text file that our Site transfers to your Device for
                record-keeping purposes and to enhance the quality of your visit
                to our Site or to enable our system to recognize your Device and
                allow us to see how you use our site. The cookie assigns a
                random, unique number to your Device. Further general
                information about cookies and how they work is available at
                www.allaboutcookies.org. The cookies we use on our Site include:
                1. Session cookies: These temporary cookies expire and are
                automatically erased whenever you close your browser window. We
                use session cookies to grant our users access to content and to
                enable actions they must be logged in to do. 2. Persistent
                cookies: These usually have an expiration date in the distant
                future and remain in your browser until they expire or you
                manually delete them. We use persistent cookies to better
                understand usage patterns so we can improve the Site for our
                users. For example, we may use a persistent cookie to associate
                you with your user account or to remember your choices on the
                Site, but this only works when cookies on our site are enabled.
                3. Third party cookies: We may permit limited third parties to
                place cookies through the Site to provide us with better
                insights into the use of the Site or user demographics or to
                provide relevant advertising to you. These third parties may
                collect information about a user’s online activities over time
                and across different websites when he or she uses our Site. For
                example, we may use analytics service providers like Google
                Analytics to analyze usage patterns of the Site. These providers
                generate a cookie to capture information about your use of the
                Site which is then used to compile reports on Site activity for
                us and to provide other related services. These providers may
                use a portion of your IP address to identify their cookie, but
                this will not be associated with any other data held by these
                providers. We may also permit third party service providers to
                place cookies through our Site, as indicated above, to perform
                analytic or marketing functions where you are notified of them
                and you have consented to the usage. We do not control the use
                of such third party cookies or the resulting information and we
                are not responsible for any actions or policies of such
                third-party service providers. Your use of our Site will be
                deemed to be your consent to the placement of cookies on your
                computer or other Device. If you prefer not to receive cookies
                through our Site, you can set your browser to either reject all
                cookies, to allow only “trusted” websites to set them, or to
                accept only those cookies from those sites you are currently on.
                Unless you have adjusted your browser setting so that it will
                refuse cookies, our system will issue cookies when you log on to
                our Site and use it. If you refuse cookies, this will limit
                system performance and may even cause certain features of the
                Site to malfunction or not to work at all.
              </p>

              <h3
                style={{
                  color: "#007bff",
                  fontSize: "1.25rem",
                  margin: "20px 0 10px",
                }}
              >
                Use of Information
              </h3>
              <p
                style={{
                  fontSize: "1rem",
                  lineHeight: "1.6",
                  marginBottom: "15px",
                }}
              >
                The use of your information is subject to this Privacy Policy
                and the Terms. The provisions contained herein supersede all
                previous notices or statements regarding our privacy practices
                with respect to this Site. We may use aggregated data
                (independent of any personal identifiers) for research and
                commercial purposes. This information includes what areas of our
                Site our users visit most frequently and what services they
                access most often. This information helps us develop a better
                Site that is more useful and accessible to you. We may use your
                information internally for our general commercial purposes
                including, among other things, to improve our Site or our
                services, or to offer our own, our affiliates’ or third-party
                products and services that we think you may find of interest. We
                may use your contact information to contact you to respond to
                your inquiries or your account or to provide information about
                us or our services to you. You can elect not to receive
                promotional emails from us either by “unsubscribing” to an email
                you receive or by contacting us as indicated below. California
                Do-Not-Track Disclosures We currently do not use technology that
                recognizes a “do-not-track” signal from your web browser.
              </p>
              <p
                style={{
                  fontSize: "1rem",
                  lineHeight: "1.6",
                  marginBottom: "15px",
                }}
              >
                Disclosing Your Information
              </p>
              <p
                style={{
                  fontSize: "1rem",
                  lineHeight: "1.6",
                  marginBottom: "15px",
                }}
              >
                We may reveal information about you to unaffiliated third
                parties: If you request or authorize it; 1. If the information
                is provided to help complete a transaction for you; 2. If the
                information is provided to comply with the law, applicable
                regulations, governmental and quasi-governmental requests, court
                orders or subpoenas, to enforce our Terms or other agreements,
                or to protect our rights, property or safety or the rights,
                property or safety of our users or others (e.g., to a consumer
                reporting agency for fraud protection, etc.); 3. If the
                disclosure is done as part of a purchase, transfer or sale of
                services or assets (e.g., in the event that substantially all of
                our assets are acquired by another party, customer information
                may be one of the transferred assets); 4. If the information is
                provided to our agents, outside vendors or service providers to
                perform functions on our behalf (e.g., analyzing data, providing
                marketing assistance, providing customer service, processing
                orders, etc.); 5. To allow them to market to you; 6. As
                otherwise described in this Privacy Policy. 7. We may disclose
                your non-private, aggregated, or otherwise non-personal
                information, such as usage statistics of our Site and services,
                to our affiliates and third parties. We may share your
                information with our affiliates for their general commercial
                use.
              </p>
              <p
                style={{
                  fontSize: "1rem",
                  lineHeight: "1.6",
                  marginBottom: "15px",
                }}
              >
                Your Choices
              </p>
              <p
                style={{
                  fontSize: "1rem",
                  lineHeight: "1.6",
                  marginBottom: "15px",
                }}
              >
                If you do not wish to receive promotional offers from us in the
                future, you can “opt out” by contacting us at the address
                indicated at the end of this Privacy Policy or by following the
                “unsubscribe” instructions in any promotional email you receive
                from us. Please be aware that you are not able to opt out of
                receiving communications about your account or your transactions
                with us
              </p>

              <h3
                style={{
                  color: "#007bff",
                  fontSize: "1.25rem",
                  margin: "20px 0 10px",
                }}
              >
                Accessing Your Information
              </h3>
              <p
                style={{
                  fontSize: "1rem",
                  lineHeight: "1.6",
                  marginBottom: "15px",
                }}
              >
                You are required to provide us with accurate and up to date
                information. NURSD LLC. gives you the ability to amend your
                account information at any time by accessing your account on our
                Site and modifying the information on your account page. Any
                changes will affect only future uses of your information.
                Subject to any legislation that might, from time to time, oblige
                us to store the information for a certain period of time, we
                will respect your wishes to correct inaccurate information.
                Otherwise, we will hold your information for as long as we
                believe it will help us to achieve our aims as detailed in this
                Privacy You can ask us whether we are keeping personal
                information about you and you can ask to receive a copy of that
                personal information. Before sending you any personal data, we
                will ask you to provide proof of your identity. If you are not
                able to provide proof of your identity, we reserve the right to
                refuse to send you any personal data. We will respond as quickly
                as we can to your requests for details of personal information,
                we hold about you.
              </p>
              <h3
                style={{
                  color: "#007bff",
                  fontSize: "1.25rem",
                  margin: "20px 0 10px",
                }}
              >
                Security
              </h3>
              <p
                style={{
                  fontSize: "1rem",
                  lineHeight: "1.6",
                  marginBottom: "15px",
                }}
              >
                We utilize reasonable administrative, physical and technical
                safeguards to protect information against unauthorized access,
                misuse or disclosure. We endeavor to protect information that
                you transfer to our Site by using secure technology such as SSL.
                Information you send by email is not secure, so please avoid
                including personal information in any email you send us. No
                method of electronic transmission or storage is 100% secure.
                Therefore, we cannot guarantee absolute security of your
                information. You also play a role in protecting your
                information. Please safeguard your user name and password and do
                not share them with others. If we receive instructions using
                your log-in information, we will consider that you have
                authorized the instructions. You agree to notify us immediately
                of any unauthorized use of your account or any other breach of
                security. We reserve the right to refuse service, terminate
                accounts, or remove or edit content in our sole discretion. Your
                California Privacy Rights For California Residents Only. We may
                disclose your personal information to our affiliates or other
                third parties who may use that information to market to you
                directly. Pursuant to California law, California residents are
                permitted to request information about the manner in which we
                share certain categories of information with third parties for
                their marketing use. Please email us at support@nursdhealth.com
                to request a copy of our disclosure pursuant to California law.
              </p>

              <h3
                style={{
                  color: "#007bff",
                  fontSize: "1.25rem",
                  margin: "20px 0 10px",
                }}
              >
                Links to Third-Party Sites
              </h3>
              <p
                style={{
                  fontSize: "1rem",
                  lineHeight: "1.6",
                  marginBottom: "15px",
                }}
              >
                When you use the Site, you may be directed to other websites
                that are beyond our control. We may also allow third-party sites
                or applications to link to the Site. We are not responsible for
                the privacy practices of any third parties or the content of
                linked sites, but we do encourage you to read the applicable
                privacy policies and terms and conditions of such parties or web
                sites. This Privacy Policy only applies to this Site.
              </p>

              <h3
                style={{
                  color: "#007bff",
                  fontSize: "1.25rem",
                  margin: "20px 0 10px",
                }}
              >
                Permanently Delete Your NURSD App Account
              </h3>
              <p
                style={{
                  fontSize: "1rem",
                  lineHeight: "1.6",
                  marginBottom: "15px",
                }}
              >
                What happens if I permanently delete my NURSD account? • You
                won't be able to reactivate your account. • Your profile,
                photos, posts, videos, and everything else you've added will be
                permanently deleted. You won't be able to retrieve anything
                you've added. • You'll no longer be able to use NURSD Apps. •
                Some information, like messages you sent to Managers, may still
                be visible to them after you delete your account. Copies of
                messages you have sent are stored in your manager’s inboxes.
                What if I don't want all my content deleted, but I want to take
                a break from NURSD? You can take a break from NURSD and
                temporarily deactivate your account. When you temporarily
                deactivate your account: • People won't be able to see or go to
                your NURSD profile. • Your data won't be deleted. • You can
                choose to come back whenever you want by contacting your manager
                or support@nursdhealth.com
              </p>

              <h3
                style={{
                  color: "#007bff",
                  fontSize: "1.25rem",
                  margin: "20px 0 10px",
                }}
              >
                Learn how to permanently delete your NURSD account.
              </h3>
              <p
                style={{
                  fontSize: "1rem",
                  lineHeight: "1.6",
                  marginBottom: "15px",
                }}
              >
                We're currently updating our NURSD settings. NURSD settings may
                not look the same for everyone. To permanently delete your NURSD
                account: 1. Click more menu at the bottom right of your app
                screen. 2. Select Contact 3. Click Deactivation and Deletion,
                then click Continue to account deletion. Can I cancel my account
                deletion? If it's been less than 30 days since you initiated the
                deletion, you can cancel your account deletion. After 30 days,
                your account and all your information will be permanently
                deleted, and you won't be able to retrieve your information. It
                may take up to 90 days from the beginning of the deletion
                process to delete all the things you've posted. While we're
                deleting this information, it's not accessible to other people
                using NURSD. Copies of your information may remain after the 90
                days in backup storage that we use to recover in the event of a
                disaster, software error, or other data loss event. We may also
                keep your information for things like legal issues, terms
                violations, or harm prevention efforts. Learn more about our
                Privacy Policy. To cancel your account deletion: Send an email
                to support@nursdhealth.com within 30 days of deleting your
                account with a request to cancel your account deletion.
              </p>

              <h3
                style={{
                  color: "#007bff",
                  fontSize: "1.25rem",
                  margin: "20px 0 10px",
                }}
              >
                Contacting Us
              </h3>
              <p
                style={{
                  fontSize: "1rem",
                  lineHeight: "1.6",
                  marginBottom: "15px",
                }}
              >
                This Site is owned and operated by NURSD LLC. If you have any
                questions or suggestions with respect to this Privacy Policy, or
                if you wish to ask us to delete, correct, or show you your
                information as provided above, you can click on Contact Us in
                our site footer or to send us an email at
                support@nursdhealth.com.
              </p>
            </section>

            {/* Terms of Use Section */}
            <section
              style={{
                display: tab === "terms" ? "block" : "none",
              }}
            >
              <h2
                style={{
                  color: "#007bff",
                  fontSize: "1.75rem",
                  fontWeight: "600",
                  marginBottom: "15px",
                }}
              >
                Terms and Conditions of Use of NURSD Site and Services
              </h2>
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: "1rem",
                  marginBottom: "10px",
                }}
              >
                NURSD Terms of Service Agreement for Nurses and Allied
                Professionals
              </p>
              <p
                style={{
                  fontSize: "1rem",
                  marginBottom: "15px",
                }}
              >
                Last Revised: [1-31-25]
              </p>
              <p
                style={{
                  fontSize: "1rem",
                  lineHeight: "1.6",
                  marginBottom: "15px",
                }}
              >
                Please read this Terms of Service Agreement{" "}
                <span style={{ fontWeight: "bold" }}>("TOS Agreement")</span>{" "}
                carefully...
              </p>

              <p
                style={{
                  fontSize: "1rem",
                  lineHeight: "1.6",
                  marginBottom: "15px",
                }}
              >
                This TOS Agreement between NURSD, LLC., an Illinois company,
                ("NURSD", "we", "us", or "our") and the user ("you" or "User")
                govern the use of: (i) our websites, https://nursdhealth.com/
                and https://nursd.app and https://nursdinc.com (the "Website");
                (ii) the services and resources available or enabled via our
                Website (the "Services"); and (iii) all content, including,
                designs, graphics, text, illustrations, icons, multimedia, and
                other material that you see or read, and all related code
                (collectively, "Our Content"). Collectively the Website,
                Services, and Our Content are "Our Properties." This TOS
                Agreement applies to all users visiting, accessing, or using Our
                Properties. By clicking the "I accept" button, completing the
                registration process, or browsing our Website, you represent
                that: (1) you have read, understand, and agree to be bound by
                this TOS Agreement, (2) you are of legal age to form a binding
                contract with NURSD, and (3) you have the authority to enter
                into the TOS Agreement personally or on behalf of the company
                (e.g. a Medical Facility) you named as the User, and to bind
                that company to the TOS Agreement. For clarity, the term "you"
                refers to the individual or legal entity, as applicable,
                identified as the User when you registered on the Website. IF
                YOU DO NOT AGREE TO BE BOUND BY THIS TOS AGREEMENT, YOU MAY NOT
                ACCESS OR USE OUR PROPERTIES. NURSD DOES NOT EMPLOY, RECOMMEND,
                ENDORSE, OR GUARANTEE ANY PROFESSIONALS OR MEDICAL FACILITIES
                (AS DEFINED IN SECTION 2 (NURSD IS A VENUE)). OUR PROPERTIES ARE
                A VENUE FOR USERS TO CONNECT ONLINE. EACH USER IS SOLELY
                RESPONSIBLE FOR INTERACTING WITH AND SELECTING ANOTHER USER,
                CONDUCTING ALL NECESSARY DUE DILIGENCE, AND COMPLYING WITH ALL
                APPLICABLE LAWS. THE ARBITRATION AGREEMENT IN SECTION 17
                (ARBITRATION) OF THIS TOS AGREEMENT CONTAINS PROVISIONS
                GOVERNING HOW CLAIMS THAT YOU AND WE HAVE AGAINST EACH OTHER ARE
                RESOLVED. IN PARTICULAR, THE ARBITRATION AGREEMENT WILL, WITH
                LIMITED EXCEPTIONS, REQUIRE DISPUTES BETWEEN US TO BE SUBMITTED
                TO BINDING AND FINAL ARBITRATION. (1) YOU WILL ONLY BE PERMITTED
                TO PURSUE CLAIMS AND SEEK RELIEF AGAINST US ON AN INDIVIDUAL
                BASIS, NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY CLASS OR
                REPRESENTATIVE ACTION OR PROCEEDING; AND (2) YOU ARE WAIVING
                YOUR RIGHT TO SEEK RELIEF IN A COURT OF LAW AND TO HAVE A JURY
                TRIAL ON YOUR CLAIMS. ANY DISPUTE OR CLAIM RELATING IN ANY WAY
                TO YOUR USE OF OUR PROPERTIES WILL BE GOVERNED AND INTERPRETED
                BY AND UNDER THE LAWS OF THE STATE OF ILLINOIS, CONSISTENT WITH
                THE FEDERAL ARBITRATION ACT, WITHOUT GIVING EFFECT TO ANY
                PRINCIPLES THAT PROVIDE FOR THE APPLICATION OF THE LAW OF ANY
                OTHER JURISDICTION. THE UNITED NATIONS CONVENTION ON CONTRACTS
                FOR THE INTERNATIONAL SALE OF GOODS IS EXPRESSLY EXCLUDED FROM
                THIS TOS AGREEMENT. Your use of, and participation in, certain
                Services may be subject to additional terms ("Supplemental
                Terms"). Such Supplemental Terms will either be listed in this
                TOS Agreement or will be presented to you for your acceptance
                when you sign up to use the supplemental Service. If the TOS
                Agreement is inconsistent with the Supplemental Terms, the
                Supplemental Terms shall control with respect to such Service.
                This TOS Agreement and any applicable Supplemental Terms are
                referred to herein as the "Agreement." Except as provided in
                sections 17.8 and 19.7, below, this Agreement is subject to
                change by us in our sole discretion at any time, such changes
                will not apply to arbitrations pending at the time the change is
                made. Please regularly check our Website to view the
                then-current Agreement. When we make changes, we will make a new
                copy of the TOS Agreement available on the Website, make any new
                Supplemental Terms available on the affected Services on the
                Website, and update the 'Last Revised' date at the top of this
                TOS Agreement. Any changes to the Agreement will be effective
                immediately for new users of Our Properties and will be
                effective thirty (30) days after posting notice of such changes
                on our Website for existing Users (defined in Section 2 (NURSD
                is a Venue)). We may require you to provide consent to the
                updated Agreement in a specified manner before we permit further
                use of Our Properties. If you do not agree to any change(s)
                after receiving a notice of such change(s), you shall stop using
                Our Properties. Otherwise, your continued use of any of Our
                Properties constitutes your acceptance of such change(s).
              </p>

              <p
                style={{
                  color: "#007bff",
                  fontWeight: "bold",
                  fontSize: "1rem",
                  marginBottom: "10px",
                }}
              >
                Questions & Concerns
              </p>
              <p
                style={{
                  fontSize: "1rem",
                  lineHeight: "1.6",
                  marginBottom: "15px",
                }}
              >
                If you have questions, complaints, or claims with respect to Our
                Properties, please contact us at the contact information below.
                We will do our best to address your concerns. If you feel your
                concerns were not addressed completely, we invite you to let us
                know for further investigation. Mail: NURSD, LLC. Attn: Support
                1771 Dewes St Unit C Glenview, IL 60025 Email:
                support@nursdhealth.com Phone: (312) 585-5499
              </p>

              <p
                style={{
                  color: "#007bff",
                  fontWeight: "bold",
                  fontSize: "1rem",
                  marginBottom: "10px",
                }}
              >
                NURSD is a Venue
              </p>
              <p
                style={{
                  fontSize: "1rem",
                  lineHeight: "1.6",
                  marginBottom: "15px",
                }}
              >
                Our Properties provide an online venue that connect users who
                are independent third-party providers of medical services (e.g.
                nurses and medical technicians) and are willing to fill
                short-term staffing positions ("Professionals") with users who
                are independent third-parties that seek to contract with such
                Professionals for short-term staffing positions ("Medical
                Facilities"). Each Medical Facility's request for Professional
                Services that is submitted on Our Properties is hereinafter
                referred to as a "Request." For each Request, the Professional
                who accepted the Request shall perform, for the duration of the
                short-term staffing position, the medical services that the
                Medical Facility entered in its Request ("Professional
                Services"). Collectively, Professionals and Medical Facilities
                are our "Users." 2.1 Staffing Requests. Each Request will be for
                one Professional (i.e. to retain two Professionals, two Requests
                must be submitted). Each Request must contain the nature and
                type of Professional Services required from the Professional
                (e.g. I need a medical technician for a heart surgery),
                including, for example, a description of the needed services,
                the start and end time of the position, the location where the
                Professional Services must be performed, whether the Request is
                for an independent contractor or temporary employee, and other
                information that may be necessary to complete the Request. Each
                Request that a Professional has accepted and has fully performed
                to the satisfaction of the Medical Facility is hereinafter
                referred to as a "Completed Request." Each Request for
                Professional Services that occurs on a recurring or regular
                basis (e.g. every day or certain days every week) is hereinafter
                referred to as a "Recurring Request." 2.2 Only a Venue. We only
                offer a method for Medical Facilities and Professionals to
                communicate and contract with one another, and a medium to
                exchange money between Professionals and Medical Facilities. You
                acknowledge and agree that by submitting or accepting a Request,
                you enter into an agreement directly with another User. You
                acknowledge and agree that NURSD is not a party to such
                agreements. We disclaim all liability arising from or related to
                any such agreements between Medical Facilities and Professionals
                except as related to our obligation to process payments pursuant
                to the Agreement.
              </p>
              <p
                style={{
                  fontSize: "1rem",
                  lineHeight: "1.6",
                  marginBottom: "15px",
                }}
              >
                NURSD does not: • Provide or Request Professional Services, •
                Have or take any responsibility or liability for any
                Professional Services provided to Medical Facilities by
                Professionals, • Take part in the interactions between Users, •
                Provide training, equipment, materials, or supervision to
                Professionals; • Have control over the manner, means, quality,
                timing, legality, or failure to provide Professional Services or
                any aspect whatsoever of any feedback or ratings provided by
                Users; or • Have control over the integrity, responsibility, or
                any actions of any Users. 2.3 Worker Classification. Medical
                Facilities are responsible for making their own decision
                regarding Professionals that they engage for Professional
                Services. Medical Facilities assume all liability for proper
                classification of Professionals as independent contractors or
                employees based on applicable legal guidelines. NURSD does not
                have authority to enter into written or oral—whether implied or
                express—contracts on behalf of the Medical Facility. NURSD does
                not, in any way, supervise, direct or control the Professionals'
                work or services performed in any manner. NURSD does not set the
                Professional's work hours and location of work, nor is NURSD
                involved in determining the type or manner of compensation to be
                paid for any Request. NURSD will not provide Professionals with
                training or any equipment, tools, labor or materials needed for
                a particular Request. NURSD will not deduct any amount for
                withholding, unemployment, social security, or other taxes as it
                would in the case of an employee. Users will be solely
                responsible for all tax returns and payments required to be
                filed with or made to any federal, state, or local tax
                authority, in any state, with respect to the Professionals'
                performance of the Professional Services. For contracts
                classified as independent contractor relationships, Medical
                Facilities may not require an exclusive relationship between the
                Professional and the Medical Facility. A Professional classified
                as an independent contractor is free at all times to perform
                Requests, be employed by otherwise engage with persons or
                businesses other than the Medical Facility, including any
                competitor of the Medical Facility. For contracts classified as
                employer-employee relationships, Medical Facilities will manage
                the Request through NURSD's payrolling program, where the
                Professional becomes an hourly employee of NURSD's staffing
                affiliate the Medical Facility and the Professional enters into
                appropriate additional agreements. Any disputes related to the
                Professional Services must be resolved directly between
                Professionals and Medical Facilities.
              </p>
              <p
                style={{
                  color: "#007bff",
                  fontWeight: "bold",
                  fontSize: "1rem",
                  marginBottom: "10px",
                }}
              >
                Ownership
              </p>
              <p
                style={{
                  fontSize: "1rem",
                  lineHeight: "1.6",
                  marginBottom: "15px",
                }}
              >
                3.1 Our Properties. You agree that NURSD and its affiliates,
                parents, subsidiaries, officers, employees, agents
                (“Affiliates”), licensors, partners, and service providers
                (“Suppliers”) own all rights, title, and interest in Our
                Properties (including the computer code, artwork, animations,
                methods of operation, moral rights, documentation, and features
                and functionality of our Website and software). NURSD, its
                Affiliates, and Suppliers also reserve all rights not granted in
                the Agreement. 3.2 Copyright & Trademark. Our Properties are
                protected by copyright and other intellectual property laws
                throughout the world and all related graphics and logos on or in
                connection with Our Properties (the “NURSD Marks”) are our
                trademarks and may not be used in connection with any
                third-party services or products without our prior written
                permission. Any other trademarks, service marks, and trade names
                that appear on Our Properties are the property of their
                respective owners. 3.3 Open Source Software. Portions of Our
                Properties may include software that is subject to various “open
                source” or “free” licenses (“Open Source Software”). Open Source
                Software is subject to the terms and conditions imposed by the
                licensors of that Open Source Software (“Open Source Terms”).
                You agree that your use of Open Source Software is subject to
                and governed by the applicable Open Source Terms. To the extent
                of any conflict between any Open Source Terms and the Agreement,
                the Open Source Terms shall prevail in connection with the
                related Open Source Software. Notwithstanding anything to the
                contrary herein, we do not make any warranty with respect to
                Open Source Software. 3.4 Your Content & License. All content
                that you upload, post, transmit, or otherwise make available
                (“Make Available”) through Our Properties is hereinafter
                referred to as “Your Content.” We do not claim ownership of Your
                Content. You hereby grant to NURSD a fully paid, royalty-free,
                perpetual, irrevocable, worldwide, non-exclusive, and fully
                sublicensable right (including any moral rights) and license to:
                (i) use, license, distribute, reproduce, modify, adapt, publicly
                perform, and publicly display Your Content that is submitted to
                Our Properties. You warrant that the holder of any worldwide
                intellectual property rights, including moral rights, in Your
                Content, has completely and effectively waived all such rights
                and validly and irrevocably granted to you the right to grant
                the license stated above. You agree that you, not NURSD, are
                responsible for all of Your Content that you Make Available on
                Our Properties. 3.5 Other User Content. Except with respect to
                Your Content, you agree that you have no right or title in or to
                any content that appears on Our Properties. 3.6 Feedback. You
                agree that submission of any ideas, suggestions, comments, and
                proposals to us (“Feedback”) is at your own risk and that we
                have no obligations (including without limitation obligations of
                confidentiality) with respect to such Feedback. You represent
                and warrant that you have all rights necessary to submit
                Feedback. You hereby grant to NURSD a fully paid, royalty-free,
                perpetual, irrevocable, worldwide, non-exclusive, and fully
                sublicensable right and license to use, reproduce, perform,
                display, distribute, adapt, modify, re-format, create derivative
                works of, and otherwise commercially or non-commercially exploit
                in any manner all Feedback, and to sublicense the foregoing
                rights, in connection with the operation, maintenance, and
                enhancement of Our Properties.
              </p>
              <p
                style={{
                  color: "#007bff",
                  fontWeight: "bold",
                  fontSize: "1rem",
                  marginBottom: "10px",
                }}
              >
                Using Our Properties
              </p>
              <p
                style={{
                  fontSize: "1rem",
                  lineHeight: "1.6",
                  marginBottom: "15px",
                }}
              >
                4.1 License. Subject to your compliance with the Agreement, we
                hereby grant you a limited, revocable, non-exclusive,
                non-transferable, non-assignable (except pursuant to Section
                19.2 (Assignment)) license to access, browse, and view any of
                Our Properties made available to our Users, and to reproduce
                portions of Our Properties available to Users for the sole
                purpose of using the Services for your personal and internal
                business purposes. 4.2 Responsibility for Content. All Users of
                Our Properties, including you, are responsible for content those
                Users Make Available through Our Properties (“User Content”).
                4.3 No Obligation to Pre-Screen Content. You acknowledge and
                agree that we have no obligation to pre-screen any content,
                including User Content, although we reserve the right, in our
                sole discretion, to pre-screen, refuse, and remove any User
                Content. You hereby provide your irrevocable consent to such
                monitoring, and you acknowledge and agree that you have no
                expectation of privacy concerning the transmission of Your
                Content. In the event that we pre-screen, refuse, or remove any
                User Content, you acknowledge that we do so for our benefit, not
                yours. 4.4 Exercise Caution. When interacting with other Users,
                you should exercise caution and common sense to protect your
                personal safety and property, just as you would when interacting
                with other persons whom you don’t know. You are solely
                responsible for your interactions with other Users of Our
                Properties. We nor our Affiliates or third-party providers are
                responsible for the conduct, whether online or offline, of any
                User of our Services. 4.5 Electronic Communications. You and
                NURSD use electronic means to communicate, whether you use the
                Services or send us emails, or whether NURSD posts notices on
                the Services or communicates with you via email. For contractual
                purposes, you: (a) consent to receive communications, including
                notifications, from NURSD in an electronic form; and (b) agree
                that all terms and conditions, agreements, notices, disclosures,
                and other communications that NURSD provides to you
                electronically satisfy any legal requirement that such
                communications would satisfy if they were in a hardcopy writing.
                The foregoing does not affect your non-waivable rights. 4.6
                Updates. You understand that Our Properties are evolving. As a
                result, you acknowledge and agree that we may update Our
                Properties with or without notifying you. You may need to update
                third-party software from time to time in order to use Our
                Properties. 4.7 Services May Vary. You acknowledge and agree
                that the selection and availability of our Services may vary
                from device to device (because of technical limitations), and
                user-to-user. 4.8 Certain Restrictions. The rights granted to
                you in the Agreement are subject to the following restrictions:
                • You shall not license, sell, rent, lease, transfer, assign,
                reproduce, distribute, host or otherwise commercially exploit
                Our Properties or any portion of Our Properties, including the
                Website; • Except as expressly stated herein, you shall not
                copy, modify, reproduce, distribute, republish, download,
                display, post, repost, or transmit, in any forms or by any
                means, any part of Our Properties; • You shall not remove or
                destroy any copyright, trademark, service mark, or other
                proprietary rights notices or markings contained on or in Our
                Properties; • You shall not frame or use framing techniques to
                enclose any of Our Properties, including any trademark, logo,
                images, text, or page layout; • You shall not use any metatags
                or other “hidden text” using NURSD Marks; • You shall not
                modify, translate, adapt, merge, or make derivative works of any
                part of Our Properties; and • You shall not use any manual or
                automated software, devices or other processes (including
                spiders, robots, scrapers, crawlers, avatars, data mining tools
                or the like) to “scrape” or download data from any web pages
                contained in the Website (except that we grant the operators of
                public search engines revocable permission to use spiders to
                copy materials from the Website for the sole purpose of and
                solely to the extent necessary for creating publicly available
                searchable indices of the materials, but not caches or archives
                of such materials). Any future release, update or other addition
                to Our Properties shall be subject to the Agreement. Any
                unauthorized use of Our Properties terminates the licenses
                granted by NURSD within the Agreement.
              </p>
              <p
                style={{
                  color: "#007bff",
                  fontWeight: "bold",
                  fontSize: "1rem",
                  marginBottom: "10px",
                }}
              >
                Registration
              </p>
              <p
                style={{
                  fontSize: "1rem",
                  lineHeight: "1.6",
                  marginBottom: "15px",
                }}
              >
                To access our Services, you must be a User who has a registered
                account on our Website (“Account”). Unregistered users (i.e.
                visitors) are only permitted to browse our Website in accordance
                with the Agreement, and may not access or use our Services. 5.1
                Registration Process. A User who is a Professional, may create
                an Account by visiting https://nursdhealth.com/ or
                https://nursd.app. There, you must enter the information
                requested, such as your first and last name, email address, and
                the geographic area where you are willing to perform
                Professional Services (e.g. Los Angeles). Once you enter the
                requested information and click “get started,” we will direct
                you to our registration form, where you must enter more
                information about yourself, such as health-related license
                number, phone numbers, work history, and other information we
                request. Once you enter the requested information and agree to
                be bound by the Agreement, we will review your registration form
                and call you at the phone number you provided us in order to
                interview you before we permit you to create an Account. Once
                your Account has been approved, you will receive an email from
                us. 5.2 Registration Data. In registering an Account on our
                Website, you: • Agree to provide true, accurate, current and
                complete information about yourself as prompted by the
                registration form (the “Registration Data”); and maintain and
                promptly update the Registration Data to keep it true, accurate,
                current, and complete; • Represent that you are of legal age to
                form a binding contract; and not a person barred from using Our
                Properties under the laws of the United States, your place of
                residence or any other applicable jurisdiction; • Are
                responsible for all activities that occur under your Account; •
                Agree that you shall monitor your Account to restrict use by
                minors, and you will accept full responsibility for any
                unauthorized use of your Account; • Agree not to share your
                Account or password with anyone, and you agree to notify us
                immediately of any unauthorized use of your password or any
                other breach of security; • Agree not to create an Account using
                a false identity or information, or on behalf of someone other
                than yourself; and • Agree not to create any Account or use Our
                Properties if we have previously removed you, or we previously
                banned you from any of Our Properties. 5.3 Inaccurate
                Information. If you provide any information that is untrue,
                inaccurate, not current or incomplete, or we have reasonable
                grounds to suspect that such information is untrue, inaccurate,
                not current or incomplete, we have the right to suspend or
                terminate your Account and refuse any and all current or future
                use of Our Properties (or any portion thereof). 5.4 Background
                Information on Professionals. NURSD works with third party
                partners to perform identity verification, sex offender registry
                checks, global watch list registry check, national criminal
                records check, county criminal records checks on Professionals
                and drug screenings, and we receive information from them such
                as publicly available information about a Professional's
                criminal history. 5.5 Primary Source Verification. NURSD works
                with third party partners or government entities to perform
                primary source verification on Professional's
                license/certification, and we receive information from them such
                as publicly available information about a Professional's
                license.
              </p>
              <p
                style={{
                  color: "#007bff",
                  fontWeight: "bold",
                  fontSize: "1rem",
                  marginBottom: "10px",
                }}
              >
                Fees and Payments
              </p>
              <p
                style={{
                  fontSize: "1rem",
                  lineHeight: "1.6",
                  marginBottom: "15px",
                }}
              >
                6.1 Types of Fees. Upon completion of a Request, the Medical
                Facility that made the Request will be charged: (1) a fee for
                the Professional’s performance of the Professional Services
                (“Professional Fee”); and (2) a fee for use of our Services
                (“NURSD Fee”). Collectively, the Professional Fee and NURSD Fee
                are the “Fees.” All Fees are in US dollars unless otherwise
                specified herein or on the Services. 6.2 Amount of Fees. The
                Medical Facility that submitted the Request will be charged the
                amount of Fees listed on Our Properties (or if not listed on Our
                Properties, the amount communicated to the Medical Facility by
                NURSD prior to submission of the Request). For a full list of
                our Fees please visit our “Fee Policy”. Unless otherwise
                negotiated by the Professional, NURSD calculates these Fees at
                the default pricing rates based on the local market rate for the
                type of service that the Professional is engaged to perform and
                based on input from the Professionals. After a Professional
                accepts a job, the pricing rate for that job cannot be adjusted.
                The Fees charged for each Request varies as follows: • NURSD
                Fee. The NURSD Fee varies depending on the geographic location
                the Professional Services are to be performed and Professional
                Services described in the Request. • Professional Fee. The
                Professional Fee for engaging a Professional as an independent
                contractor varies depending on the length of the engagement, the
                geographic location where the Professional Services are
                performed, and other factors, such as the Professional’s
                experience and other negotiated terms. If a Professional, as an
                independent contractor, works over eight consecutive hours, the
                Medical Facility shall pay a greater hourly rate to that
                Professional (the “Surge Rate”) as follows: (i) if the
                Professional works over eight (8) but less than twelve (12)
                consecutive hours, the Surge Rate shall be one and a half (1.5)
                times the hourly rate of that Professional for each consecutive
                hour the Professional works beyond eight (8) hours and less than
                twelve (12) hours; and (ii) if the Professional works over
                twelve (12) consecutive hours, the Surge Rate shall be two (2)
                times the hourly rate of that Professional for each consecutive
                hour the Professional works beyond twelve (12) hours. The
                Professional Fee for engaging a Professional as an employee will
                be determined based on the type of engagement and geographic
                location of the Professional. 6.3 Fees Subject to Change. You
                agree that we, in our sole discretion, may modify and increase
                the Fees at any time and for any reason. If we modify the Fees,
                the modified Fees will not apply to Requests that were submitted
                prior to the modification. 6.4 Our Payment Processor. We use
                Stripe Inc. (“Stripe”) as a third party service provider for
                payment services for independent contractor engagements,
                including to process Fees. For employee engagements, NURSD will
                process the payment through a third party vendor who will issue
                the payment as a paycheck. Stripe will store any Payment
                Provider Account information entered on Our Properties and will
                charge such Payment Provider Accounts as outlined herein. By
                paying or receiving funds through Our Properties, you agree to
                be bound by the Stripe US Services Agreement available at:
                https://stripe.com/us/legal. If applicable, Stripe will report
                amounts paid to Professionals by filing Form 1099-MISC with the
                Internal Revenue Services, as required by law. For more
                information about the way we use and share your personal
                information with our third-party payment processor, please visit
                our Privacy Policy available at:
                https://nursdhealth.com/privacy-policy. 6.5 Cancellation. The
                Medical Facility may elect to cancel its request for Services at
                any time prior to the commencement of such Services. In the
                event of such cancellation, we may charge a cancellation fee to
                the Medical Facility on your behalf. 6.6 No Circumvention. 1.
                Payment for Requests through NURSD. Our value rests in our
                thriving venue of Professionals and Medical Facilities. NURSD
                receives payment when a Medical Facility pays and a Professional
                receives that payment through the NURSD platform. For 24 months
                from the time a User identifies or is identified by any party
                through the platform (“Non-Circumvention Period”), unless you
                pay the Opt-Out Fee in Section 6.6(B), you agree to use the
                platform as the exclusive method to request, make and receive
                all payments for work directly or indirectly with that party or
                arising out of your relationship with that party (“NURSD
                Relationship”). It is a violation of the Agreement during the
                Non-Circumvention Period for a Professional to knowingly accept
                (a) a payment of Fees outside of the context of Our Properties
                for a Request found on our platform; or (b) a Request with the
                intention of delegating the performance of the Professional
                Services to another health-care professional. YOU SHALL
                IMMEDIATELY NOTIFY US IF ANOTHER USER REQUESTS THAT PAYMENT BE
                MADE THROUGH CHANNELS OTHER THAN THOSE PROVIDED OR SPECIFIED BY
                NURSD. 2. Opting Out. Users may opt-out of the obligation in
                Section 6.6(A) with respect to each NURSD Relationship if the
                User pays NURSD an opt-out fee for each such relationship
                (“Opt-Out Fee”). The Opt-Out Fee is computed as follows: 25% of
                the anticipated annualized salary or wages for one year if the
                Medical Facility offers the Professional permanent employment or
                per-diem. NURSD, in its sole discretion, may determine whether a
                User violated Section 6.6(A). In the event a violation is
                identified, NURSD or its affiliates may (1) charge your Payment
                Provider the Opt-Out Fee (including interest), to the extent
                permitted by law, or may send you an invoice for the Opt-Out Fee
                (including interest), which the User shall pay within 30 days;
                (2) terminate your account and revoke your authorization to use
                the platform; and/or (3) charge you for all losses, costs and
                reasonable expenses (including reasonable attorney’s fees)
                related to investigating such breach and the collection of such
                fees. To pay the Opt-Out Fee, you must request instructions by
                sending an email message to support@nursdhealth.com.
              </p>
              <p
                style={{
                  color: "#007bff",
                  fontWeight: "bold",
                  fontSize: "1rem",
                  marginBottom: "10px",
                }}
              >
                Additional Terms for Professionals
              </p>
              <p
                style={{
                  fontSize: "1rem",
                  lineHeight: "1.6",
                  marginBottom: "15px",
                }}
              >
                For purposes of this Section 7, “you” refers only to
                Professionals (and no other Users). The following terms apply
                only to Professionals: • In addition to receiving electronic
                communications from us as described in Section 4.5 (Electronic
                Communications), you consent to receiving text messages and
                phone calls from us at the phone number you provided us when you
                created an Account for the purpose of providing, and improving
                our Services to our Users. For example, we will notify you,
                through our third-party service provider, via a text-message
                and/or phone call, to notify you of the date, hours, and
                location(s) of the Request(s) you accepted, or of the recent
                Request(s) that may be of interest to you. You are solely
                responsible for any costs you incur when receiving text messages
                from us, including any carrier charges that apply for receiving
                such text messages. • You acknowledge and agree that we do not
                and cannot guarantee that Medical Facilities will request your
                Professional Services, that any Medical Facility will engage you
                to perform Professional Services, or that a Request will not be
                cancelled by the Medical Facility. • You represent that you are
                duly licensed (as applicable) and have the experience,
                qualifications, and ability to perform each Request you accept.
                You further represent that you shall use your best efforts to
                perform the Professional Services for any Request you have
                accepted to the satisfaction of the Medical Facility that
                submitted that Request. • You acknowledge and agree that you are
                not the agent or representative of NURSD and are not authorized
                to make any representation, contract or commitment on behalf of
                NURSD. • You acknowledge and agree that we do not and cannot
                reimburse you for any expenses you incur as a result of your
                performance of Professional Services or for use of Our
                Properties. • YOU ACKNOWLEDGE AND AGREE THAT THERE IS NO
                EMPLOYMENT, PART-TIME EMPLOYMENT, CONSULTING, CONTRACTOR,
                PARTNERSHIP, OR JOINT VENTURE RELATIONSHIP WHATSOEVER BETWEEN
                YOU AND US. NURSD is not an employment service or agency and
                does not secure employment for you. You acknowledge and agree
                that you will not be entitled to, and hereby elect not to
                participate in (on either a prospective or retrospective basis),
                any of the benefits that NURSD makes available to its employees,
                such as group insurance, profit-sharing or retirement benefits
                (and waive any rights to receive any such benefits). 7.1
                Professional Registration Data. In addition to the terms set
                forth in Section 5.2 (Registration Data), you: • Represent that
                any health-care related license numbers (e.g. Registered Nurse
                license number) (“Licenses”) you provide us are valid and in
                force; • Represent that your License(s) will remain in full
                force for so long as you make yourself available to provide
                Professional Services hereunder; and • Agree to promptly notify
                us and all Medical Facilities that you have agreed to perform
                future Professional Services for you if you: (i) become barred
                from practicing in any jurisdiction, (ii) lose any of your
                License(s), (iii) are facing disciplinary actions, or (iv) make
                any changes to your Registration Data. 7.2 Verify Information.
                You acknowledge and agree that we have the right, but not the
                obligation, to independently verify your Registration Data and
                any other statements you make on Our Properties for the purpose
                of verifying that those statements are accurate and complete
                (“Verification Purposes”). In the event that NURSD chooses to
                verify the representations, statements or any information
                provided by you through your use of Our Properties, you hereby
                authorize NURSD, either directly or indirectly through
                third-party vendors or service providers, to attempt to verify
                such information, which verification may include, without
                limitation, conducting checks related to your health care
                registration and/or license, checks related to your background,
                and or checks with available public records for Verification
                Purposes. You consent to any collection, use or disclosure in
                order to accomplish such verification, and agree to provide any
                documentation or information at NURSD’s request to facilitate
                these processes. For information about how we use your personal
                information in relation to these checks, please see our Privacy
                Policy available at: https://nursdhealth.com/privacy-policy. 7.3
                Professional Payment Terms. • Payment of Fees. We will inform
                you of the varying amounts of Fees when we interview you as
                described in Section 5.1 (Registration Process). If you perform
                a service under an employee engagement, your fees will be issued
                to you from a third-party payroll provider as a paycheck. If you
                perform a service under an independent contractor engagement,
                the Professional Fees owed to you will be deposited in your bank
                account as described in Section 7.3.B (Bank Account Information)
                within forty-eight (48) hours of the Request being marked as a
                Completed Request on Our Properties. For clarity, if you perform
                a Recurring Request, the amount owed to you for each day your
                performance will be deposited within forty-eight (48) hours from
                the time that a Medical Facility marks your performance for that
                day as a Completed Request on Our Properties. • Bank Account
                Information. You agree to enter your current bank account
                information within your Account and agree that our third-party
                payment processors and we may transfer the Professional Fees you
                are owed, if any, upon your completion of a Request that you
                accepted to such bank account. You, and not us, are solely
                responsible for the accuracy of your bank account information,
                including your bank account number and routing information. We
                disclaim all liability related to errors in fund deposits due to
                inaccurate or incomplete bank account information. • Disclaimer.
                IF WE ARE UNABLE TO COLLECT THE PROFESSIONAL FEES THAT ARE OWED
                TO YOU, WE WILL INFORM YOU IN WRITING. HOWEVER, WE HAVE NO
                OBLIGATION TO IMPOSE COLLECTION PROCEDURES ON MEDICAL FACILITIES
                AND DO NOT GUARANTEE PAYMENT FROM MEDICAL FACILITIES. • Taxes.
                You are solely responsible for all tax returns and payments
                required to be filed with or made to any U.S. federal, state, or
                local tax authority with respect to your performance of
                Professional Services and receipt of Professional Fees under
                this TOS Agreement. You agree to accept exclusive liability for
                complying with all applicable state and federal laws, including
                laws governing self-employed individuals, if applicable, such as
                laws related to payment of taxes, social security, disability,
                and other contributions based on Professional Fees paid to you
                by Medical Facilities under this TOS Agreement. NURSD will not
                withhold or make payments for taxes, social security,
                unemployment insurance or disability insurance contributions, or
                obtain workers’ compensation insurance on your behalf. You
                hereby agree to indemnify and defend NURSD against any and all
                such taxes or contributions, including penalties and interest.
                We cannot and do not offer tax advice to our Users. 7.4
                Insurance. You acknowledge that you are an independent
                contractor, not an employee of NURSD. As such, you acknowledge
                and understand that you are not covered by any insurance that
                may be provided by NURSD to its employees, including, without
                limitation, health insurance, workers compensation insurance,
                general liability insurance, and automobile liability insurance.
                NURSD requires that, as an independent contractor, you maintain
                workers’ compensation or occupational accident insurance for
                which you are solely and exclusively responsible for.
                Specifically, in the event that you are injured while working in
                the course and scope of an engagement for NURSD, you acknowledge
                and understand that you will not be covered by any workers
                compensation insurance coverage that NURSD may provide to its
                employees. Further, in the event that your actions cause an
                injury to a third party while you are working in the course and
                scope of performing a NURSD Engagement or the Services, you
                acknowledge and understand that you will not be covered by any
                general liability or automobile liability insurance coverage
                that NURSD may have, and that NURSD is not making any commitment
                to defend and/or indemnify you in such circumstances, and
                specifically denies such obligation. If you do not provide proof
                of workers’ compensation or occupational accident insurance, you
                will be charged a Trust and Safety Fee that will be paid towards
                NURSD’s supplemental occupational accident insurance. By
                agreeing to these Terms, you are agreeing to pay this fee.
              </p>
              <p
                style={{
                  color: "#007bff",
                  fontWeight: "bold",
                  fontSize: "1rem",
                  marginBottom: "10px",
                }}
              >
                Acceptable User Conduct
              </p>
              <p
                style={{
                  fontSize: "1rem",
                  lineHeight: "1.6",
                  marginBottom: "15px",
                }}
              >
                When accessing or using Our Properties, you agree that you will
                not, under any circumstances: • Fail to perform any Requests you
                accepted; • Misrepresent your identity or authority in any way;
                • Contact Users for any purpose other than as set forth in the
                Agreement; • Solicit any services other than Professional
                Services through Our Properties; • Interfere with other Users’
                Requests, Feedback, User Content, or interfere with or undermine
                the working of Our Properties; • Make Available any unauthorized
                advertising; promotional materials, junk mail, or spam; • Make
                Available Your Content that is: (i) false, inaccurate, unlawful,
                defamatory, obscene; (ii) racially, ethnically, or otherwise
                objectionable; (iii) violates, or encourages any conduct that
                would violate the Agreement or any applicable laws or
                regulations or would give rise to civil liability; (iv) promotes
                hatred, harassment, or harm against and other Users; or (v)
                promotes illegal or harmful activities; • Breach or circumvent
                any laws, third party rights, or our systems, policies, or
                determinations of your Account status; or • Interfere with or
                damage Our Properties, including, without limitation through use
                of viruses, cancel bots, Trojan horses, harmful code, flood
                pings, denial-of-service attacks, packet or IP spoofing, forged
                routing or electronic mail address information, or similar
                methods or technology. Indemnification You agree to indemnify
                and hold NURSD, its Affiliates, and Suppliers (collectively,
                “Indemnified Parties”) harmless from any losses, costs,
                liabilities and expenses (including reasonable attorneys’ fees)
                relating to or arising out of: (a) your use of, or inability to
                use, Our Properties; (b) your violation of the Agreement; (c)
                your violation of any rights of another party, including any
                Users; or (d) your violation of any applicable laws, rules or
                regulations. For the avoidance of doubt, this indemnification
                provision applies to any claims that a Professional was
                misclassified as an independent contractor, and any claims
                arising from or related to such misclassification. We reserve
                the right, at our own cost, to assume the exclusive defense and
                control of any matter otherwise subject to indemnification by
                you, in which event you will fully cooperate with us in
                asserting any available defenses. Additionally, Users agree to
                indemnify, hold harmless and defend NURSD from any and all
                claims arising out of or related to their Request, including but
                not limited to claims that a Professional was misclassified as
                an independent contractor, any liabilities arising from a
                determination by a court, arbitrator, government agency or other
                body that a Professional was misclassified (including, but not
                limited to taxes, penalties, interest and attorney’s fees), any
                claim that NURSD was an employer or joint employer of a
                Professional, as well as claims under any employment-related
                laws, such as those relating to employment termination,
                employment discrimination, harassment or retaliation, as well as
                any claims for overtime pay, sick leave, holiday or vacation
                pay, retirement benefits, worker’s compensation benefits,
                unemployment benefits, or any other employee benefits. This
                Section 9 does not require you to indemnify any of the
                Indemnified Parties for any unconscionable commercial practice
                by such party or for such party’s fraud, deception, false
                promise, misrepresentation or concealment, suppression or
                omission of any material fact in connection with the Website or
                any Services provided hereunder. You agree that the provisions
                in this Section 9 will survive any termination of your Account,
                the Agreement, or your access to Our Properties.
              </p>
              <p
                style={{
                  color: "#007bff",
                  fontWeight: "bold",
                  fontSize: "1rem",
                  marginBottom: "10px",
                }}
              >
                Disclaimer of Warranties for Our Properties
              </p>
              <p
                style={{
                  fontSize: "1rem",
                  lineHeight: "1.6",
                  marginBottom: "15px",
                }}
              >
                10.1 As Is. YOU EXPRESSLY UNDERSTAND AND AGREE THAT TO THE
                EXTENT PERMITTED BY APPLICABLE LAW, YOUR USE OF OUR PROPERTIES
                IS AT YOUR SOLE RISK, AND OUR PROPERTIES ARE PROVIDED ON AN “AS
                IS” AND “AS AVAILABLE” BASIS, WITH ALL ITS FAULTS, INCLUDING
                OMISSIONS AND ERRORS. NURSD, ITS AFFILIATES, AND ITS SUPPLIERS
                EXPRESSLY DISCLAIM ALL WARRANTIES, REPRESENTATIONS, AND
                CONDITIONS OF ANY KIND, WHETHER EXPRESS OR IMPLIED, ARISING FROM
                USE OF OUR WEBSITE. NURSD, ITS AFFILIATES, AND ITS SUPPLIERS DO
                NOT MAKE ANY WARRANTY, REPRESENTATION OR CONDITION THAT OUR
                PROPERTIES WILL MEET YOUR REQUIREMENTS OR BE UP-TO-DATE, OR THAT
                YOUR USE OF OUR PROPERTIES WILL BE UNINTERRUPTED, TIMELY, SECURE
                OR ERROR-FREE. NO INFORMATION OR ADVICE, WHETHER ORAL OR
                WRITTEN, OBTAINED FROM NURSD, ITS AFFILIATES, ITS SUPPLIERS, OR
                THROUGH OUR PROPERTIES WILL CREATE ANY WARRANTY, GUARANTEE, OR
                REPRESENTATION NOT EXPRESSLY MADE HEREIN. ANY CONTENT ACCESSED
                THROUGH OUR PROPERTIES IS ACCESSED AT YOUR OWN RISK, AND YOU
                SHALL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR PROPERTY,
                INCLUDING YOUR COMPUTER SYSTEM AND ANY DEVICE YOU USE TO ACCESS
                OUR PROPERTIES, OR ANY OTHER LOSS THAT RESULTS FROM ACCESSING
                SUCH CONTENT. 10.2 Your Use. NURSD, ITS AFFILIATES, AND ITS
                SUPPLIERS WILL NOT BE LIABLE FOR ANY CLAIM, INJURY, OR DAMAGE
                ARISING FROM OR IN CONNECTION WITH YOUR USE OF OUR PROPERTIES.
                10.3 No Liability for Conduct of Third Parties. YOU ACKNOWLEDGE
                AND AGREE THAT NURSD, ITS AFFILIATES, AND ITS SUPPLIERS ARE NOT
                LIABLE, AND YOU AGREE NOT TO SEEK TO HOLD US, OUR AFFILIATES AND
                OUR SUPPLIERS LIABLE, FOR THE CONDUCT OF USERS ON THE WEBSITE,
                AND OPERATORS OF EXTERNAL SITES, AND THAT THE RISK OF INJURY
                FROM SUCH USERS RESTS ENTIRELY WITH YOU. 10.4 No Liability for
                User Interactions. NURSD, ITS AFFILIATES, AND ITS SUPPLIERS
                DISCLAIM ALL LIABILITY FOR ANY ACT OR OMISSION OF ANY USER; AND
                YOU ACKNOWLEDGE THAT NURSD IS NOT ABLE TO CONTROL OR SUPERVISE
                YOUR INTERACTIONS OR TRANSACTIONS WITH OTHER USERS OF OUR
                PROPERTIES. YOU AGREE THAT YOU ARE SOLELY RESPONSIBLE FOR SUCH
                INTERACTIONS OR TRANSACTIONS. NURSD RESERVES THE RIGHT, BUT HAS
                NO OBLIGATION, TO (I) MONITOR ANY SUCH DISPUTE OR (II) UPON YOUR
                REQUEST, INTERVENE IN SUCH DISPUTE FOR THE PURPOSE OF RESOLVING
                THE DISPUTE, PROVIDED THAT NURSD WILL NOT BE RESPONSIBLE FOR ANY
                DAMAGES WHATSOEVER ARISING FROM ANY MONITORING OR INTERVENTION
                OF SUCH ACTIVITIES.
              </p>
              <p
                style={{
                  color: "#007bff",
                  fontWeight: "bold",
                  fontSize: "1rem",
                  marginBottom: "10px",
                }}
              >
                Limitation of Liability
              </p>
              <p
                style={{
                  fontSize: "1rem",
                  lineHeight: "1.6",
                  marginBottom: "15px",
                }}
              >
                11.1 Disclaimer of Certain Damages. YOU UNDERSTAND AND AGREE
                THAT IN NO EVENT SHALL NURSD, ITS AFFILIATES, OR ITS SUPPLIERS
                BE LIABLE FOR ANY: (i) LOSS OF PROFITS, REVENUE OR DATA; (ii)
                INDIRECT, INCIDENTAL, SPECIAL, OR CONSEQUENTIAL DAMAGES ARISING
                OUT OF IN CONNECTION WITH OUR PROPERTIES; (iii) DAMAGES OR COSTS
                DUE TO PROCUREMENT OF SUBSTITUTE SERVICES, OR (IV) LIABILITY,
                CLAIMS, CAUSES OF ACTION, OR DAMAGES ARISING FROM YOUR USE OF
                OUR PROPERTIES OR THAT ARE IN ANY WAY RELATED TO THE USERS
                INTRODUCED TO YOU BY OUR PROPERTIES, WHETHER OR NOT WE HAVE BEEN
                ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, ARISING OUT OF OR IN
                CONNECTION WITH THE AGREEMENT OR OUR PROPERTIES. 11.2 Quality of
                Professional Services. THE QUALITY OF PROFESSIONAL SERVICES
                REQUESTED THROUGH THE USE OF OUR PROPERTIES IS ENTIRELY THE
                RESPONSIBILITY OF THE PROFESSIONAL WHO PROVIDES SUCH
                PROFESSIONAL SERVICES. MEDICAL FACILITIES UNDERSTAND THAT BY
                USING THE SERVICES, THEY MAY BE EXPOSED TO SERVICES THAT ARE
                POTENTIALLY HARMFUL, UNSAFE, OR OTHERWISE OBJECTIONABLE, AND
                THAT USE OF THE PROFESSIONAL SERVICES, AND SUCH PROFESSIONALS,
                IS AT THEIR OWN RISK. 11.3 Cap on Liability. UNDER NO
                CIRCUMSTANCES WILL NURSD, ITS AFFILIATES, OR ITS SUPPLIERS BE
                LIABLE TO YOU FOR MORE THAN THE GREATER OF (1) THE TOTAL AMOUNT
                PAID TO NURSD IN RELATION TO YOUR REQUESTS DURING THE
                TWELVE-MONTH PERIOD PRIOR TO THE ACT, OMISSION, OR OCCURRENCE,
                GIVING RISE TO SUCH LIABILITY, OR (2) THE REMEDY OR PENALTY
                IMPOSED BY THE STATUTE UNDER WHICH SUCH CLAIM ARISES. THE
                FOREGOING CAP ON LIABILITY SHALL NOT APPLY TO ANY LIABILITY OF
                NURSD OR ITS AFFILIATES FOR NEGLIGENCE, FRAUD OR FRAUDULENT
                MISREPRESENTATION. 11.4 Potential Restrictions. CERTAIN
                JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
                CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE
                ABOVE EXCLUSIONS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU
                MIGHT HAVE ADDITIONAL RIGHTS. 11.5 Basis of Bargain. The
                limitations of liability set forth above are fundamental
                elements of the basis of the bargain between you and us.
              </p>
              <p
                style={{
                  color: "#007bff",
                  fontWeight: "bold",
                  fontSize: "1rem",
                  marginBottom: "10px",
                }}
              >
                Copyright Infringement Claims
              </p>
              <p
                style={{
                  fontSize: "1rem",
                  lineHeight: "1.6",
                  marginBottom: "15px",
                }}
              >
                In accordance with the Digital Millennium Copyright Act of 1988,
                NURSD promptly responds to claims of copyright infringement
                committed on Our Properties if such claims are reported to our
                designated copyright agent. If you believe your work has been
                posted on Our Properties in a way that constitutes copyright
                infringement, please deliver the following information to our
                designated copyright agent at the address below: • An electronic
                or physical signature of the person authorized to act on behalf
                of the owner of the copyright interest; • A description of the
                copyrighted work that you claim has been infringed; • A
                description of the location on Our Properties of the material
                that you claim is infringing; • Your address, telephone number
                and email address; • written statement by you that you have a
                good faith belief that the disputed use is not authorized by the
                copyright owner, its agent or the law; and • A statement by you,
                made under penalty of perjury, that the above information in
                your notice is accurate and that you are the copyright owner or
                authorized to act on the copyright owner’s behalf. Our
                designated copyright agent: support@nursdhealth.com
              </p>
              <p
                style={{
                  color: "#007bff",
                  fontWeight: "bold",
                  fontSize: "1rem",
                  marginBottom: "10px",
                }}
              >
                Violations
              </p>
              <p
                style={{
                  fontSize: "1rem",
                  lineHeight: "1.6",
                  marginBottom: "15px",
                }}
              >
                If we become aware of any possible violations, or if we, in our
                sole discretion, determine that you breached any portion of the
                Agreement or have otherwise demonstrated conduct inappropriate
                for Our Properties, we reserve the right to: Investigate your
                behavior, including any possible violations; Refer the matter
                to, and cooperate with any and all applicable legal authorities;
                Disclose any information on Our Properties to comply with
                applicable laws, legal processes or governmental requests;
                Deactivate your Account and cancel your Requests (or your
                acceptance of Requests) without issuing a refund or payment to
                you; Notify Users you have interacted or transacted with;
                Enforce the Agreement; and Pursue any other action that we deem
                to be appropriate.
              </p>
              <p
                style={{
                  color: "#007bff",
                  fontWeight: "bold",
                  fontSize: "1rem",
                  marginBottom: "10px",
                }}
              >
                Term & Termination
              </p>
              <p
                style={{
                  fontSize: "1rem",
                  lineHeight: "1.6",
                  marginBottom: "15px",
                }}
              >
                14.1 Term. The Agreement shall commence on: (i) the date you
                accept the Agreement or (ii), if earlier, the date you first
                used any of Our Properties (the “Effective Date”), and will
                remain in full force and effect while you use Our Properties
                unless terminated earlier in accordance with the Agreement. 14.2
                Termination. We reserve the right to block access to, suspend,
                or terminate your access to Our Properties or your Account, with
                or without notice, if we, in our sole discretion, determine that
                you are in breach of the Agreement. 14.3 Effect of Termination.
                In the event that we terminate your access to Our Properties
                and/or your ability to create an Account, we may remove your
                access from, bar your right to further use, and delete your
                password and Account on our Website. Termination of access to
                Our Properties shall terminate the Agreement. All provisions of
                the Agreement that should survive termination, shall survive,
                including ownership provisions, warranty disclaimers, and
                limitations of liability. 14.4 No Subsequent Registration. If we
                discontinue your ability to access Our Properties and/or create
                an Account, you agree that you shall not attempt to re-register
                with or access Our Properties, for example, by using a different
                username. In the event you violate this Section 14.4, we reserve
                the right, in our sole discretion, to immediately take any and
                all of the actions set forth herein without any notice or
                warning to you.
              </p>
              <p
                style={{
                  color: "#007bff",
                  fontWeight: "bold",
                  fontSize: "1rem",
                  marginBottom: "10px",
                }}
              >
                International Users
              </p>
              <p
                style={{
                  fontSize: "1rem",
                  lineHeight: "1.6",
                  marginBottom: "15px",
                }}
              >
                Our Properties can be accessed from countries around the world,
                but our Services are only available in the United States. If you
                are a User outside the United States, any references to Services
                in your country does not imply any intention to announce such
                Services in your country. NURSD makes no representations that
                Our Properties are appropriate or available for use in locations
                outside the United States. Those who access or use Our
                Properties from other countries do so at their own volition and
                are responsible for compliance with local law.
              </p>
              <p
                style={{
                  color: "#007bff",
                  fontWeight: "bold",
                  fontSize: "1rem",
                  marginBottom: "10px",
                }}
              >
                No Third Party Beneficiaries
              </p>
              <p
                style={{
                  fontSize: "1rem",
                  lineHeight: "1.6",
                  marginBottom: "15px",
                }}
              >
                You acknowledge and agree that, except as otherwise expressly
                provided in the Agreement with the exception of the arbitration
                provisions set forth in Section 17 below, there shall be no
                third party beneficiaries in to the Agreement.
              </p>
              <p
                style={{
                  color: "#007bff",
                  fontWeight: "bold",
                  fontSize: "1rem",
                  marginBottom: "10px",
                }}
              >
                ARBITRATION
              </p>
              <p
                style={{
                  fontSize: "1rem",
                  lineHeight: "1.6",
                  marginBottom: "15px",
                }}
              >
                Please read the following arbitration agreement in this Section
                17 (“Arbitration Agreement”) carefully. It requires you to
                arbitrate disputes with NURSD and limits the manner in which you
                can seek relief from us. 17.1 Applicability of Arbitration
                Agreement. You agree that any dispute or claim relating in any
                way to your access or use of our Website, to any Services
                distributed through our Website, or to any aspect of your
                relationship with NURSD, (each, a “Dispute”) will be resolved by
                binding arbitration, rather than in court, except for the
                exceptions expressly stated in the Agreement. The following
                Disputes are not subject to this Arbitration Agreement: (1)
                Disputes that qualify for small claims court, so long as the
                Dispute remains in such court and advances only on an individual
                (non-class, non-representative) basis; and (2) any Dispute
                related to, or arising from, allegations of theft, piracy,
                invasion of privacy, or unauthorized use of our Website; and (3)
                you or NURSD may seek injunctive or other equitable relief in
                court for infringement or other misuse of intellectual property
                rights (such as trademarks, trade dress, domain names, trade
                secrets, copyrights, and patents). IF YOU AGREE TO ARBITRATION
                WITH NURSD, YOU ARE AGREEING IN ADVANCE THAT YOU WILL NOT
                PARTICIPATE IN OR SEEK TO RECOVER MONETARY OR OTHER RELIEF IN
                ANY LAWSUIT FILED AGAINST NURSD ALLEGING CLASS, COLLECTIVE,
                AND/OR REPRESENTATIVE CLAIMS ON YOUR BEHALF, UNLESS OTHERWISE
                PERMITTED BY LAW. INSTEAD, BY AGREEING TO ARBITRATION, YOU MAY
                BRING YOUR CLAIMS AGAINST NURSD IN AN INDIVIDUAL ARBITRATION
                PROCEEDING. IF SUCCESSFUL ON SUCH CLAIMS, YOU COULD BE AWARDED
                MONEY OR OTHER RELIEF PERMITTED BY APPLICABLE LAW BY AN
                ARBITRATOR. YOU ACKNOWLEDGE THAT YOU HAVE BEEN ADVISED THAT YOU
                MAY CONSULT WITH AN ATTORNEY IN DECIDING WHETHER TO ACCEPT THE
                AGREEMENT, INCLUDING THIS ARBITRATION AGREEMENT. 17.2
                Arbitration Rules and Forum. The Federal Arbitration Act (9
                U.S.C. §§ 1 et seq.) governs this Arbitration Agreement. To
                begin an arbitration proceeding, you must send a letter
                requesting arbitration and describing your claim to our
                corporate office 1771 Dewes St Unit C, Glenview, IL 60025. The
                arbitration will be conducted by JAMS, an established
                alternative dispute resolution provider, or another alternative
                resolution provider based on the written agreement of the
                parties. Disputes involving claims and counterclaims under
                $250,000, not inclusive of attorneys’ fees and interest, shall
                be subject to JAMS’s most current version of the Streamlined
                Arbitration Rules and procedures available at
                http://www.jamsadr.com/rules-streamlined-arbitration/; all other
                claims shall be subject to JAMS’s most current version of the
                Comprehensive Arbitration Rules and Procedures, available at
                http://www.jamsadr.com/rules-comprehensive-arbitration/. JAMS’s
                rules are also available at www.jamsadr.com or by calling JAMS
                at 800-352-5267. If JAMS is not available to arbitrate, the
                parties will select an alternative arbitral forum. If the
                arbitrator finds that you cannot afford to pay the filing,
                administrative, hearing and/or other fees of JAMS, or another
                alternative dispute resolution provider, and you cannot obtain a
                waiver from JAMS, NURSD will pay them for you. In addition,
                NURSD will reimburse all such filing, administrative, hearing
                and/or other fees for claims totaling less than $10,000 unless
                the arbitrator determines the claims are frivolous. Likewise,
                NURSD will not seek attorneys’ fees and costs in arbitration
                unless the arbitrator determines the claims are frivolous and
                NURSD would otherwise be entitled to an award of such fees and
                costs had the matter been heard in court. You may choose to have
                the arbitration conducted by telephone or online
                videoconferencing service, based on written submissions, or in
                person in the county where you live or at another mutually
                agreed location. Any judgment on the award rendered by the
                arbitrator may be entered in any court of competent
                jurisdiction. 17.3 Authority of Arbitrator. The arbitrator, and
                not any federal, state or local court or agency shall have
                exclusive authority to resolve any dispute related to the
                interpretation, applicability, enforceability or formation of
                this Arbitration Agreement including any claim that all or any
                part of this Arbitration Agreement is void or voidable. The
                arbitrator will decide the rights and liabilities, if any, of
                you and NURSD. The arbitration proceeding will not be
                consolidated with any other matters or joined with any other
                cases or parties unless all parties (including NURSD) consent in
                writing. The arbitrator shall have the authority to grant
                motions dispositive of all or part of any claim. The arbitrator
                shall have the authority to award monetary damages and to grant
                any non-monetary remedy or relief available to an individual
                under applicable law, and the TOS Agreement (including the
                Arbitration Agreement). The arbitrator shall issue a written
                award and statement of decision describing the essential
                findings and conclusions on which the award is based, including
                the calculation of any damages awarded. The arbitrator has the
                same authority to award relief on an individual basis to the
                same extent that a judge in a court of law would have. The award
                of the arbitrator is final and binding upon you and us. 17.4
                Waiver of Bench or Jury Trial. YOU AND NURSD HEREBY WAIVE ANY
                CONSTITUTIONAL AND STATUTORY RIGHTS TO SUE IN COURT AND HAVE A
                TRIAL IN FRONT OF A JUDGE OR A JURY. You and NURSD are instead
                electing that all claims and disputes shall be resolved by
                arbitration under this Arbitration Agreement, except as
                specified in Section 17.1 above. An arbitrator can award on an
                individual basis the same damages and relief as a court and must
                follow this Arbitration Agreement as a court would. However,
                there is no judge or jury in arbitration, and court review of an
                arbitration award is subject to very limited review. 17.5 Waiver
                of Class or Consolidated Actions. ALL CLAIMS AND DISPUTES WITHIN
                THE SCOPE OF THIS ARBITRATION AGREEMENT MUST BE ARBITRATED ON AN
                INDIVIDUAL BASIS AND NOT ON A CLASS BASIS, ONLY INDIVIDUAL
                RELIEF IS AVAILABLE, AND CLAIMS OF MORE THAN ONE USER CANNOT BE
                ARBITRATED OR CONSOLIDATED WITH THOSE OF ANY OTHER USER. 17.6
                Severability. If any part or parts of this Arbitration Agreement
                are found under the law to be invalid or unenforceable, then
                such specific part or parts shall be of no force and effect and
                shall be severed and the remainder of the Arbitration Agreement
                shall continue in full force and effect. 17.7 Survival of
                Arbitration This Arbitration Agreement will survive the
                termination of your relationship with NURSD. 17.8 Modification.
                Notwithstanding any provision in the Agreement to the contrary,
                you agree that if NURSD makes any future material change to this
                Arbitration Agreement, it will not apply to any individual
                claim(s) for which you have demanded arbitration or then pending
                in arbitration.
              </p>
              <p
                style={{
                  color: "#007bff",
                  fontWeight: "bold",
                  fontSize: "1rem",
                  marginBottom: "10px",
                }}
              >
                California Users & Residents
              </p>
              <p
                style={{
                  fontSize: "1rem",
                  lineHeight: "1.6",
                  marginBottom: "15px",
                }}
              >
                In accordance with California Civil Code §1789.3, you may report
                complaints to the Complaint Assistance Unit of the Division of
                Consumer Services of the California Department of Consumer
                Affairs by contacting them in writing at 400 R Street,
                Sacramento, CA 95814, or by telephone at (800) 952-5210.
              </p>
              <p
                style={{
                  color: "#007bff",
                  fontWeight: "bold",
                  fontSize: "1rem",
                  marginBottom: "10px",
                }}
              >
                General Provisions
              </p>
              <p
                style={{
                  fontSize: "1rem",
                  lineHeight: "1.6",
                  marginBottom: "15px",
                }}
              >
                19.1 Notice. Where we require that you provide an email address,
                you are responsible for providing us with your most current
                email address. If the last email address you provided to us is
                not valid, or for any reason is not capable of delivering to you
                any notices required or permitted by the Agreement, our dispatch
                of the email containing such notice will nonetheless constitute
                effective notice. You may give notice to us at the physical
                address listed in Section 1 (Questions & Concerns). Such notice
                shall be deemed given when received by NURSD by letter delivered
                by nationally recognized overnight delivery service or first
                class postage prepaid mail. 19.2 Assignment. The Agreement, and
                your rights and obligations hereunder, may not be assigned,
                subcontracted, delegated or otherwise transferred by you without
                our prior written consent, and any attempted assignment,
                subcontract, delegation, or transfer in violation of the
                foregoing will be null and void. 19.3 Force Majeure. Any delay
                or failure to perform by us or you (except the payment of money
                owed) will not be considered a breach of this Agreement if such
                delay or failure to perform is caused by causes outside our
                reasonable control, including Internet service interruptions, or
                governmental demands or requirements. 19.4 Exclusive Venue. To
                the extent the parties are permitted under the TOS Agreement to
                initiate litigation in a court, both you and NURSD agree that
                all claims and disputes arising out of or relating to the
                Agreement will be litigated exclusively in the state or federal
                courts located in Cook County, Illinois. 19.5 Governing Law. The
                Agreement and any action related thereto will be governed and
                interpreted by and under the laws of the State of Illinois,
                consistent with the Federal Arbitration Act, without giving
                effect to any principles that provide for the application of the
                law of another jurisdiction. The United Nations Convention on
                Contracts for the International Sale of Goods does not apply to
                the Agreement. 19.6 Export Control. You may not use, export,
                import, or transfer Our Properties except as authorized by U.S.
                law, the laws of the jurisdiction in which you obtained Our
                Properties, and any other applicable laws. In particular, but
                without limitation, Our Properties may not be exported or
                re-exported: (a) into any U.S. embargoed countries, or (b) to
                anyone on the U.S. Treasury Department’s list of Specially
                Designated Nationals or the U.S. Department of Commerce’s Denied
                Person’s List or Entity List. By using Our Properties, you
                represent and warrant that: (i) you are not located in a country
                that is subject to a U.S. Government embargo, or that has been
                designated by the U.S. Government as a country that supports
                terrorists, and (ii) you are not listed on any U.S. Government
                list of prohibited or restricted parties. You also will not use
                Our Properties for any purpose prohibited by U.S. law, including
                the development, design, manufacture or production of missiles,
                nuclear, chemical or biological weapons. You acknowledge and
                agree that our Services and/or technology are subject to the
                export control laws and regulations of the United States. You
                shall comply with these laws and regulations and shall not,
                without prior U.S. government authorization, export, re-export,
                or transfer our Services or technology, either directly or
                indirectly, to any country in violation of such laws and
                regulations. 19.7 Entire Agreement. The Agreement is the final,
                complete and exclusive agreement of the parties with respect to
                the subject matter hereof and supersedes and merges all prior
                discussions between the parties with respect to such subject
                matter. Subject to any limitations set forth in Section 17 for
                Arbitration, this Agreement may be amended or superseded only be
                a mutually-executed writing. As used herein, “including” means
                “including without limitation”. The headings used in the
                Agreement are included only for purposes of convenience and
                shall not limit or otherwise affect the construction of the
                Agreement. 19.8 Waiver. Any waiver or failure to enforce any
                provision of the Agreement on one occasion will not be deemed a
                waiver of any other provision or of such provision on any other
                occasion. 19.9 Severability. If any portion of the Agreement is
                held invalid or unenforceable, that portion shall be construed
                in a manner to reflect, as nearly as possible, the original
                intention of you and us, and the remaining portions shall remain
                in full force and effect.
              </p>
              <p
                style={{
                  color: "#007bff",
                  fontWeight: "bold",
                  fontSize: "1rem",
                  marginBottom: "10px",
                }}
              >
                NURSD Terms of Service Agreement for Medical Facilities
              </p>
              <p
                style={{
                  fontSize: "1rem",
                  lineHeight: "1.6",
                  marginBottom: "15px",
                }}
              >
                Last Revised: [8-08-21] Please read this Terms of Service
                Agreement (“TOS Agreement”) carefully. This TOS Agreement
                between NURSD, LLC., an Illinois based company, (“NURSD”, “we”,
                “us”, or “our”) and the user (“you” or “User”) govern the use
                of: (i) our websites, https://nursdhealth.com/ and
                https://nursd.app and https://nursdinc.com (our “Website”); (ii)
                the services and resources available or enabled via our Website
                (the “Services”); and (iii) all content, including, designs,
                graphics, text, illustrations, icons, multimedia, and other
                material that you see or read, and all related code
                (collectively, “Our Content”). Collectively the Website,
                Services, and Our Content are “Our Properties.” This TOS
                Agreement applies to all users visiting, accessing, or using Our
                Properties. By clicking the “I accept” button, completing the
                registration process, or browsing our Website, you represent
                that: (1) you have read, understand, and agree to be bound by
                this TOS Agreement, (2) you are of legal age to form a binding
                contract with NURSD, and (3) you have the authority to enter
                into the TOS Agreement personally or on behalf of the company
                (e.g. a Medical Facility) you named as the User, and to bind
                that company to the TOS Agreement. For clarity, the term “you”
                refers to the individual or legal entity, as applicable,
                identified as the User when you registered on the Website. IF
                YOU DO NOT AGREE TO BE BOUND BY THIS TOS AGREEMENT, YOU MAY NOT
                ACCESS OR USE OUR PROPERTIES. NURSD DOES NOT EMPLOY, RECOMMEND,
                ENDORSE, OR GUARANTEE ANY PROFESSIONALS OR MEDICAL FACILITIES
                (AS DEFINED IN SECTION 2 (NURSD IS A VENUE)). OUR PROPERTIES ARE
                A VENUE FOR USERS TO CONNECT ONLINE. EACH USER IS SOLELY
                RESPONSIBLE FOR INTERACTING WITH AND SELECTING ANOTHER USER,
                CONDUCTING ALL NECESSARY DUE DILIGENCE, AND COMPLYING WITH ALL
                APPLICABLE LAWS. THE ARBITRATION AGREEMENT IN SECTION 16
                (ARBITRATION) OF THIS TOS AGREEMENT CONTAINS PROVISIONS
                GOVERNING HOW CLAIMS THAT YOU AND WE HAVE AGAINST EACH OTHER ARE
                RESOLVED. IN PARTICULAR, THE ARBITRATION AGREEMENT WILL, WITH
                LIMITED EXCEPTIONS, REQUIRE DISPUTES BETWEEN US TO BE SUBMITTED
                TO BINDING AND FINAL ARBITRATION. (1) YOU WILL ONLY BE PERMITTED
                TO PURSUE CLAIMS AND SEEK RELIEF AGAINST US ON AN INDIVIDUAL
                BASIS, NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY CLASS OR
                REPRESENTATIVE ACTION OR PROCEEDING; AND (2) YOU ARE WAIVING
                YOUR RIGHT TO SEEK RELIEF IN A COURT OF LAW AND TO HAVE A JURY
                TRIAL ON YOUR CLAIMS. ANY DISPUTE OR CLAIM RELATING IN ANY WAY
                TO YOUR USE OF OUR PROPERTIES WILL BE GOVERNED AND INTERPRETED
                BY AND UNDER THE LAWS OF THE STATE OF ILLINOIS, CONSISTENT WITH
                THE FEDERAL ARBITRATION ACT, WITHOUT GIVING EFFECT TO ANY
                PRINCIPLES THAT PROVIDE FOR THE APPLICATION OF THE LAW OF ANY
                OTHER JURISDICTION. THE UNITED NATIONS CONVENTION ON CONTRACTS
                FOR THE INTERNATIONAL SALE OF GOODS IS EXPRESSLY EXCLUDED FROM
                THIS TOS AGREEMENT. Your use of, and participation in, certain
                Services may be subject to additional terms (“Supplemental
                Terms”). Such Supplemental Terms will either be listed in this
                TOS Agreement or will be presented to you for your acceptance
                when you sign up to use the supplemental Service. If the TOS
                Agreement is inconsistent with the Supplemental Terms, the
                Supplemental Terms shall control with respect to such Service.
                This TOS Agreement and any applicable Supplemental Terms are
                referred to herein as the “Agreement.” Except as provided in
                Sections 17.8 and 19.7, below, the Agreement is subject to
                change by us in our sole discretion at any time, such changes
                will not apply to arbitrations pending at the time the change is
                made. Please regularly check our Website to view the
                then-current Agreement. When we make changes, we will make a new
                copy of the TOS Agreement available on the Website, make any new
                Supplemental Terms available on the affected Services on the
                Website, and update the ‘Last Revised’ date at the top of this
                TOS Agreement. Any changes to the Agreement will be effective
                immediately for new users of Our Properties and will be
                effective thirty (30) days after posting notice of such changes
                on our Website for existing Users (defined in Section 2 (NURSD
                is a Venue)). We may require you to provide consent to the
                updated Agreement in a specified manner before we permit further
                use of Our Properties. If you do not agree to any change(s)
                after receiving a notice of such change(s), you shall stop using
                Our Properties. Otherwise, your continued use of any of Our
                Properties constitutes your acceptance of such change(s).
              </p>
              <p
                style={{
                  color: "#007bff",
                  fontWeight: "bold",
                  fontSize: "1rem",
                  marginBottom: "10px",
                }}
              >
                Questions & Concerns
              </p>
              <p
                style={{
                  fontSize: "1rem",
                  lineHeight: "1.6",
                  marginBottom: "15px",
                }}
              >
                If you have questions, complaints, or claims with respect to Our
                Properties, please contact us at the contact information below.
                We will do our best to address your concerns. If you feel your
                concerns were not addressed completely, we invite you to let us
                know for further investigation. Mail: NURSD, LLC. Attn: Support
                1771 Dewes St Unit C Glenview, IL 60025 Email:
                support@nursdhealth.com Phone: (312) 585-5499
              </p>
              <p
                style={{
                  color: "#007bff",
                  fontWeight: "bold",
                  fontSize: "1rem",
                  marginBottom: "10px",
                }}
              >
                NURSD is an Online Venue
              </p>
              <p
                style={{
                  fontSize: "1rem",
                  lineHeight: "1.6",
                  marginBottom: "15px",
                }}
              >
                Our Properties provide an online venue that connect users who
                are independent third-party providers of medical services (e.g.
                nurses and medical technicians) and are willing to fill
                short-term staffing positions (“Professionals”) with users who
                are independent third-parties that seek to contract with such
                Professionals for short-term staffing positions (“Medical
                Facilities”). Each Medical Facility’s request for Professional
                Services that is submitted on Our Properties is hereinafter
                referred to as a “Request.” For each Request, the Professional
                who accepted the Request shall perform, for the duration of the
                short-term staffing position, the medical services that the
                Medical Facility entered in its Request (“Professional
                Services”). Collectively, Professionals and Medical Facilities
                are our “Users.” 2.1 Staffing Requests. Each Request will be for
                one Professional (i.e. to retain two Professionals, two Requests
                must be submitted). Each Request must contain the nature and
                type of Professional Services required from the Professional
                (e.g. I need a medical technician for a heart surgery),
                including, for example, a description of the needed services,
                the start and end time of the position, the location where the
                Professional Services must be performed, whether the Request is
                for an independent contractor or temporary employee, and other
                information that may be necessary to complete the Request. Each
                Request that a Professional has accepted and has fully performed
                to the satisfaction of the Medical Facility is hereinafter
                referred to as a “Completed Request.” Each Request for
                Professional Services that occurs on a recurring or regular
                basis (e.g. every day or certain days every week) is hereinafter
                referred to as a “Recurring Request.” 2.2 Only a Venue. We only
                offer a method for Medical Facilities and Professionals to
                communicate and contract with one another, and a medium to
                exchange money between Professionals and Medical Facilities. You
                acknowledge and agree that by submitting or accepting a Request,
                you enter into an agreement directly with another User. You
                acknowledge and agree that NURSD is not a party to such
                agreements. We disclaim all liability arising from or related to
                any such agreements between Medical Facilities and Professionals
                except as related to our obligation to process payments pursuant
                to the Agreement. NURSD does not: • Provide or Request
                Professional Services, • Have or take any responsibility or
                liability for any Professional Services provided to Medical
                Facilities by Professionals, • Take part in the interactions
                between Users, • Provide training, equipment, materials, or
                supervision to Professionals; • Have control over the manner,
                means, quality, timing, legality, or failure to provide
                Professional Services or any aspect whatsoever of any feedback
                or ratings provided by Users; or • Have control over the
                integrity, responsibility, or any actions of any Users. 2.3
                Worker Classification. Medical Facilities are responsible for
                making their own decision regarding Professionals that they
                engage for Professional Services and should conduct their own
                investigations prior to engaging any Professionals. Although
                Professionals must undergo background checks, phone interviews
                and other verification processes in order to create an Account,
                we make no representations about and disclaim all liability
                associated with: (i) the suitability, reliability, and
                timeliness of the Professional Services provided by
                Professionals; and (ii) each Professional’s credentials,
                background, suitability, and reliability. Medical Facilities
                assume all liability for proper classification of Professionals
                as independent contractors or employees based on applicable
                legal guidelines. Medical Facilities further warrant and
                represent that all classification decisions are proper and made
                in accordance with applicable law. This Agreement does not
                create a partnership or agency relationship between the Medical
                Facility and NURSD. NURSD does not have authority to enter into
                written or oral –whether implied or express –contracts on behalf
                of the Medical Facility. NURSD does not, in any way, supervise,
                direct or control the Professionals’ work or services performed
                in any manner. NURSD does not set the Professional’s work hours
                and location of work, nor is NURSD involved in determining the
                type or manner of compensation to be paid for any Request. NURSD
                will not provide Professionals with training or any equipment,
                tools, labor or materials needed for a particular Request. NURSD
                will not deduct any amount for withholding, unemployment, social
                security, or other taxes as it would in the case of an employee.
                Users will be solely responsible for all tax returns and
                payments required to be filed with or made to any federal,
                state, or local tax authority, in any state, with respect to the
                Professionals’ performance of the Professional Services. For
                contracts classified as independent contractor relationships,
                Medical Facilities may not require an exclusive relationship
                between the Professional and the Medical Facility. A
                Professional classified as an independent contractor is free at
                all times to perform Requests, be employed by otherwise engage
                with persons or businesses other than the Medical Facility,
                including any competitor of the Medical Facility. For contracts
                classified as employer-employee relationships, Medical
                Facilities will manage the Request through NURSD’s payrolling
                program, where the Professional becomes an hourly employee of
                NURSD’s staffing affiliate the Medical Facility and the
                Professional enter into appropriate additional agreements. Users
                agree to indemnify, hold harmless and defend NURSD from any and
                all claims arising out of or related to their Request, including
                but not limited to claims that a Professional was misclassified
                as an independent contractor, any liabilities arising from a
                determination by a court, arbitrator, government agency or other
                body that a Professional was misclassified (including, but not
                limited to taxes, penalties, interest and attorney’s fees), any
                claim that NURSD was an employer or joint employer of a
                Professional, as well as claims under any employment-related
                laws, such as those relating to employment termination,
                employment discrimination, harassment or retaliation, as well as
                any claims for overtime pay, sick leave, holiday or vacation
                pay, retirement benefits, worker’s compensation benefits,
                unemployment benefits, or any other employee benefits. Any
                disputes related to the Professional Services must be resolved
                directly between Professionals and Medical Facilities.
              </p>
              <p
                style={{
                  color: "#007bff",
                  fontWeight: "bold",
                  fontSize: "1rem",
                  marginBottom: "10px",
                }}
              >
                Enterprise Customers and Users: NURSD Service Level Agreement
              </p>
              <p
                style={{
                  fontSize: "1rem",
                  lineHeight: "1.6",
                  marginBottom: "15px",
                }}
              >
                Updated Clause: For Enterprise Customers and Users: In the
                absence or expiration of any separately executed NURSD Service
                Level Agreement (SLA), these Terms and Conditions shall govern
                the relationship between the parties. This has always been the
                standard practice to ensure clarity and consistency in service
                expectations.
              </p>
              <p
                style={{
                  fontSize: "1rem",
                  lineHeight: "1.6",
                  marginBottom: "15px",
                }}
              >
                Last Updated: Jan 26, 2022 The terms of this Services Agreement
                constitute a legally binding agreement (the "Agreement") between
                you and NURSD LLC. ("NURSD," "we," "us" or "our") governing your
                use of NURSD' application(s), website(s), and technology
                platform(s) (collectively, the "Solutions"). PLEASE BE ADVISED:
                THIS AGREEMENT CONTAINS PROVISIONS THAT GOVERN HOW CLAIMS YOU
                AND NURSD HAVE AGAINST EACH OTHER CAN BE BROUGHT. THESE
                PROVISIONS WILL REQUIRE YOU TO SUBMIT CLAIMS YOU HAVE AGAINST
                NURSD TO MEDIATION, AND IF NOT RESOLVED THROUGH MEDIATION, TO
                BINDING AND FINAL ARBITRATION ON AN INDIVIDUAL BASIS, NOT AS A
                PLAINTIFF OR CLASS MEMBER IN ANY CLASS, GROUP OR REPRESENTATIVE
                ACTION OR PROCEEDING. By entering into this Agreement, you
                expressly acknowledge that you understand this Agreement and
                accept all of its terms. IF YOU DO NOT AGREE TO BE BOUND BY THE
                TERMS AND CONDITIONS OF THIS AGREEMENT, YOU MAY NOT USE OR
                ACCESS NURSD Solutions. IMPORTANT INFORMATION FOR PROFESSIONALS
                (AS DEFINED BELOW): PLEASE NOTE THAT TO USE THE NURSD SOLUTIONS,
                YOU MUST AGREE TO THE TERMS AND CONDITIONS SET FORTH BELOW.
                PLEASE REVIEW THE ARBITRATION PROVISION SET FORTH BELOW
                CAREFULLY, AS EXCEPT AS IT OTHERWISE PROVIDES OR IF YOU OPT OUT
                AS PROVIDED IN NURSD SAAS SERVICES ORDER and ANY WRITTEN
                AGREEMENT BETWEEN NURSD AND YOUR EMPLOYER ORGANIZATION, IT WILL
                REQUIRE YOU TO RESOLVE ALL LEGAL DISPUTES ARISING OUT OF OR
                RELATING TO YOUR RELATIONSHIP WITH NURSD OR AN ORGANIZATION
                THROUGH MANDATORY MEDIATION AND IF MEDIATION FAILS, ON AN
                INDIVIDUAL BASIS IN FINAL AND BINDING ARBITRATION. BY VIRTUE OF
                YOUR ELECTRONIC EXECUTION OF THESE TERMS, YOU WILL BE
                ACKNOWLEDGING THAT YOU HAVE READ AND UNDERSTOOD ALL OF THE TERMS
                OF THIS AGREEMENT (INCLUDING THE ARBITRATION PROVISION) AND HAVE
                TAKEN TIME TO CONSIDER THE CONSEQUENCES OF THIS IMPORTANT
                BUSINESS DECISION. IF YOU WANT TO OPT OUT OF ANY OF THE TERMS OR
                AGREEMENT, PLEASE DO NOT PROCEED WITH THE SIGNUP PROCESS. NURSD
                AND ANY OF ITS AFFILIATES BEARS NO RESPONSIBILITY OUTSIDE OF ANY
                AGREED AND SIGNED AGREEMENT NAMED NURSD SAAS SERVICES AGREEMENT.
                © Copyright 2022 by Nursd LLC., Inc. All rights reserved.
              </p>
            </section>
          </div>
        </div>
      </section>
    </div>
  );
}

export default SideTab;
